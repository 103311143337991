import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import HWUser from "../HWLibrary/HWUser";
import Highcharts from 'highcharts';
import SkTooltip from '../SkSmartUtil/SkTooltip/SkTooltip';
import skLogo from "../assets/phoenix/logo.svg";
import skLogo_playbookStandalone from "../assets/phoenix/logo_playbookStandalone.svg";
import "../assets/styles/digikit.css";
import { getGlobalStates, setAccountListStates, setGlobalStates } from "../store/reducers/skGlobalReducer";
import { getSmartPlayBookStates, playbookRbackScope } from "../store/reducers/skPlaybooksReducer";
import SideBarRoutes from "./SideBarRoutes";
import "./SmartSideBar.css";
import Snackbar from '@mui/material/Snackbar';
import styles from "./sidenav.module.css";
import SKSmartUiBtns from "../SkSmartUtil/SKSmartUiBtns/SKSmartUiBtns";
import SmartModal from "../SkSmartUtil/SmartModal/SmartModal";
import WelcomeSmartPlaybooksBanner from "../WelcomeSmartPlaybooksBanner";
import { HighchartsReact } from "highcharts-react-official";
import moment from "moment";
import UiLabel from "../SkSmartUtil/UiLabel/UiLabel";

const SideBar = (props) => {
	let routes = SideBarRoutes(playbookRbackScope(["CustomerPortalUser:Enabled"])).filter(prop => prop.isClickable).map(i => { return i.name });
	const myRef = useRef();
	const dispatch = useDispatch();
	const getSkGlobalState = useSelector(getGlobalStates);
	const {toasterUndoBtnClick,  toasterUndoCrossClick, globalActiveSideBarTab, persistAccount360Filter, persistNotesFilter, globalSearchSelectedAccounts, showGlobalSKSearch, showNotification, notificationMsg, showUndoToaster , showUndoToasterMessage} = getSkGlobalState;
	const location = useLocation();
	const getSmartPlayBookState = useSelector(getSmartPlayBookStates);
	const { playbookRbacResponse, disableGettingStartedTab, gettingStartedDetails } = getSmartPlayBookState;

	const [forceRefreshId, setForceRefreshId] = useState(new Date().getTime());
	const [isActive, setIsActive] = useState(playbookRbackScope(["CustomerPortalUser:Enabled"]) ? '' : getGlobalStates()?.noOfLogin > 0 && getGlobalStates()?.noOfLogin <= 5 && (HWUser.orgType() !== "playbookStandalone") ? 'Key Outcomes' : "My Workspace");
	const [hoverTab, setHoverTab] = useState('');
	const [showIcon, setShowIcon] = useState(true);
	const [vertical, setVertical] = useState('bottom');
	const [horizontal, setHorizontal] = useState('left')
	const [isSettingScreen, setIsSettingScreen] = useState(false);


	const showZoomPullover = useCallback((show) => {
		dispatch(setGlobalStates({ showZoomPullover: true }))
	}, [dispatch])


	const handleOnClick = useCallback(async (e, activeTab) => {
		e.stopPropagation();
		if (globalActiveSideBarTab === 'My Portfolio' && persistAccount360Filter) {
			if (activeTab === 'My Workspace' || activeTab === 'Key Outcomes' || activeTab === 'Product Analytics' || activeTab === 'SmartKonversations') {
				// await smartFilterTokenService?.updateSkAccountfilterOnRedirection(null, "AllAccounts", pathMapper[activeTab])
			} else if (activeTab === 'SmartInsights' || activeTab === 'Kommunity' || activeTab === 'SmartOps') {
				// await smartFilterTokenService?.updateSkAccountfilterOnRedirection(null, "AllAccounts")
			}
			dispatch(setGlobalStates({ persistAccount360Filter: false }))
		}

		if (globalActiveSideBarTab === 'My Portfolio' && globalSearchSelectedAccounts && showGlobalSKSearch) {
			if (activeTab === 'My Workspace' || activeTab === 'Key Outcomes' || activeTab === 'Product Analytics' || activeTab === 'SmartKonversations') {
				// await smartFilterTokenService?.updateSkAccountfilterOnRedirection(null, "AllAccounts", pathMapper[activeTab])
			} else if (activeTab === 'SmartInsights' || activeTab === 'Kommunity' || activeTab === 'SmartOps') {
				// await smartFilterTokenService?.updateSkAccountfilterOnRedirection(null, "AllAccounts")
			}
			dispatch(setAccountListStates({ globalSearchSelectedAccounts: null }));
			dispatch(setAccountListStates({ showGlobalSKSearch: false }));
		}


		if (globalActiveSideBarTab === 'My Portfolio' && persistNotesFilter) {
			if (activeTab === 'My Workspace' || activeTab === 'Key Outcomes' || activeTab === 'Product Analytics' || activeTab === 'SmartKonversations') {
				// await smartFilterTokenService?.updateSkAccountfilterOnRedirection(null, "AllAccounts", pathMapper[activeTab])
			}


			dispatch(setGlobalStates({ persistNotesFilter: false }))
		}

		if(activeTab !== 'Academy') {
			await props?.actionsDashboard.onClickNavMenu(activeTab);
			dispatch(setGlobalStates({ globalActiveSideBarTab: activeTab }))
			await setIsActive(activeTab);
		} 
		if (activeTab === "SmartKonversations" && HWUser.isPremiumFeatureEnable() && !HWUser.isZoomIntegratedForThisUser()) {
			e.preventDefault();
			showZoomPullover(true);
		}
		if (activeTab === "Academy") {
			e.preventDefault();
			window.open("/academy-redirect?path=workspace_tasks");
		}
	}, [dispatch, globalActiveSideBarTab, globalSearchSelectedAccounts, persistAccount360Filter, persistNotesFilter, props?.actionsDashboard, showGlobalSKSearch, showZoomPullover]);



	useEffect(() => {
		if (!routes.includes("My Workspace")) {
			setIsActive(routes[0]);

		}
	}, [routes])


	useEffect(() => {
		let pathname = window.location.pathname;
		let name = "My Workspace"
		switch (pathname) {
			case '/dashboard/csm-dashboard':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/churn/churn_touchpoints':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/churn/churn_dashboard':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/churn/churn_summary':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/churn/churn_playbooks_summary':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/churn/churn_tasks':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/renewal/renewal_dashboard':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/expansion/expansion_dashboard':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/expansion/playbooks_Summary':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/expansion/timeline':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/expansion/Touchpoints':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/renewal/renewal_summary':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/renewal/renewal_playbooks_summary':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/renewal/renewal_timeline':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/on_boarding/on_boarding_dashboard':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/on_boarding/on_boarding_playbooks_Summary':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/on_boarding/on_boarding_timeline':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/on_boarding/on_boarding_touchpoints':
				name = "Key Outcomes";
				break;
			case '/dashboard/csm-dashboard/renewal/renewal_touchpoints':
				name = "Key Outcomes";
				break;
			case '/dashboard/portfolio':
				name = "My Portfolio";
				break;
			case '/dashboard/portfolio/my_accounts':
				name = "My Portfolio";
				break;
			case '/dashboard/portfolio/segment_summary':
				name = "My Portfolio";
				break;
			case '/dashboard/portfolio/Notes':
				name = "My Portfolio";
				break;
			case '/dashboard/portfolio/Contacts':
				name = "My Portfolio";
				break;
			case '/dashboard/portfolio/account360_touchpoint_list':
				name = "My Portfolio";
				break;
			case '/dashboard/portfolio/List':
				name = "My Portfolio";
				break;
			case '/dashboard/portfolio/CustomerPortal':
				name = "My Portfolio";
				break;
			case '/dashboard/portfolio/account_playbooks':
				name = "My Portfolio";
				break;
			case '/dashboard/portfolio/Dashboard':
				name = "My Portfolio";
				break;
			case "/dashboard/product-success-console":
				name = "Product Analytics";
				break;
			case "/dashboard/product-success-console/product_usage":
				name = "Product Analytics";
				break;
			case "/dashboard/product-success-console/heatMap":
				name = "Product Analytics";
				break;
			case "/dashboard/product-success-console/duration":
				name = "Product Analytics";
				break;
			case "/dashboard/product-success-console/loyalty":
				name = "Product Analytics";
				break;
			case "/dashboard/product-success-console/feature":
				name = "Product Analytics";
				break;
			case "/dashboard/product-success-console/users":
				name = "Product Analytics";
				break;
			case '/dashboard/workspace':
				name = playbookRbackScope(["CustomerPortalUser:Enabled"]) ? "Customer Portal" : "My Workspace";
				break;
			case '/dashboard/workspace/Calendar':
				name = playbookRbackScope(["CustomerPortalUser:Enabled"]) ? "Customer Portal" : "My Workspace";
				break;
			case '/dashboard/workspace/Touchpoints':
				name = playbookRbackScope(["CustomerPortalUser:Enabled"]) ? "Customer Portal" : "My Workspace";
				break;
			case '/dashboard/workspace/timeline':
				name = playbookRbackScope(["CustomerPortalUser:Enabled"]) ? "Customer Portal" : "My Workspace";
				break;
			case '/dashboard/workspace/playbook/PlayBooksDashboard':
				name = playbookRbackScope(["CustomerPortalUser:Enabled"]) ? "Customer Portal" : "My Workspace";
				break;
			case '/dashboard/workspace/playbook/playbooks_Summary':
				name = playbookRbackScope(["CustomerPortalUser:Enabled"]) ? "Customer Portal" : "My Workspace";
				break;
			case '/dashboard/workspace/playbook/PlayBooksAssignmentRules':
				name = playbookRbackScope(["CustomerPortalUser:Enabled"]) ? "Customer Portal" : "My Workspace";
				break;
			case '/dashboard/workspace/playbook/template':
				name = playbookRbackScope(["CustomerPortalUser:Enabled"]) ? "Customer Portal" : "My Workspace";
				break;
			case '/dashboard/workspace/getting_started':
				name = playbookRbackScope(["CustomerPortalUser:Enabled"]) ? "Customer Portal" : "My Workspace";
				break;
			case '/dashboard/workspace/email/Inbox':
				name = "My Workspace";
				break;
			case '/dashboard/workspace/email/Sent':
				name = "My Workspace";
				break;
			case "/dashboard/smart-insight":
				name = "SmartInsights";
				break;
			case "/dashboard/smart-insight/canned_report/smart_hs_report":
				name = "SmartInsights";
				break;
			case "/dashboard/smart-insight/canned_report/nrr_walk":
				name = "SmartInsights";
				break;
			case "/dashboard/smart-insight/canned_report":
				name = "SmartInsights";
				break;	
			case "/dashboard/smart-insight/custom_report":
				name = "SmartInsights";
				break;	
			case "/dashboard/smart-insight/canned_report/touchpoint_report":
				name = "SmartInsights";
				break;
			case "/dashboard/kommunity":
				name = "Kommunity";
				break;
			case "/dashboard/success-intelligence":
				name = HWUser.roleType() === "NonAccount" ||  HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps';
				break;
			case "/dashboard/success-intelligence/RI":
				name = HWUser.roleType() === "NonAccount" ||  HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps';
				break;
			case "/dashboard/success-intelligence/EI":
				name = HWUser.roleType() === "NonAccount" ||  HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps';
				break;
			case "/dashboard/success-intelligence/PAI":
				name = HWUser.roleType() === "NonAccount" ||  HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps';
				break;
			case "/dashboard/success-intelligence/RiskIntelligence":
				name = HWUser.roleType() === "NonAccount" ||  HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps';
				break;
			case "/dashboard/success-intelligence/Alerts":
				name = HWUser.roleType() === "NonAccount" ||  HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps';
				break;
			case "/dashboard/success-intelligence/alerts-dashboard":
				name = HWUser.roleType() === "NonAccount" ||  HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps';
				break;
			case "/dashboard/success-intelligence/alerts-summary":
				name = HWUser.roleType() === "NonAccount" ||  HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps';
				break;
			case '/dashboard/smart-konversations':
				name = "SmartKonversations";
				break;
			case '/dashboard/smart-konversations/team_metrics':
				name = "SmartKonversations";
				break;
			case '/dashboard/smart-konversations/Summary':
				name = "SmartKonversations";
				break;
			case '/dashboard/smart-konversations/team_conversations':
				name = "SmartKonversations";
				break;
			case '/dashboard/smart-konversations/my_conversations':
				name = "SmartKonversations";
				break;
			case '/dashboard/smart-konversations/call_intelligence':
				name = "SmartKonversations";
				break;
			case '/dashboard/smart-konversations/key_cloud':
				name = "SmartKonversations";
				break;
			case '/dashboard/smart-konversations/manage_keywords':
				name = "SmartKonversations";
				break;
			case '/dashboard/smart-konversations-team':
				name = "SmartKonversations";
				break;
			case '/dashboard/touchpoint-intelligence':
				name = "SmartKonversations";
				break;
			case "/dashboard/smart-assist":
				name = "SmartAssist";
				break;
			case "/dashboard/tools":
				name = "tools";
				break;
			case "/academy-redirect":
				name = "My Workspace";
				break;
			case "/dashboard/success-intelligence/portal":
				name = "Notification";
				break;
			case "":
				name = "";
				break;
			default:
				name = "";
				break;
		}

		if (pathname.includes("settings")) {
			setIsSettingScreen(true);
			props?.actionsDashboard.setOpen(false);
		}
		else {
			setIsSettingScreen(false);
			props?.actionsDashboard.setOpen(true);
		}

		if (pathname === "/academy-redirect") {
			window.location = window.origin + "/dashboard/workspace"
		}
		let activeTab = playbookRbackScope(["CustomerPortalUser:Enabled"]) ? 'My Workspace' : name
		setIsActive(activeTab);
		dispatch(setGlobalStates({ globalActiveSideBarTab: name }));
		setForceRefreshId(new Date().getTime())
		props?.actionsDashboard.onClickNavMenu(name);
	}, [props.menuName, dispatch, props?.actionsDashboard, playbookRbacResponse, window.location.pathname])

	const welcomeSmartPlaybooksPopup = useMemo(() => {
		return {
			disableModalFooter: true,
			heading: "Welcome to SmartPlaybooks !",
			close: () => {
				dispatch(setGlobalStates({ showWelcomePlaybookPopup: false }))
			},
		}
	}, [])


	useEffect(() => {
		let state = location?.state;
		if (state && state?.activeTab?.path === 'account_360') {
			setIsActive("My Portfolio")
			dispatch(setGlobalStates({ globalActiveSideBarTab: "My Portfolio" }))
		}
		if (state && state?.activeTab?.path === 'smart_notification_alert_dashboard') {
			setIsActive(HWUser.roleType() === "NonAccount" ||  HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps')
			dispatch(setGlobalStates({ globalActiveSideBarTab: HWUser.roleType() === "NonAccount" ||  HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps' }))
		}
	}, [location?.state])

	const [customerPortalUser, setCustomerPortalUser] = useState(true);

	useEffect(() => {
		let CustomerPortalUser = playbookRbackScope(["CustomerPortalUser:Enabled"])
		if (CustomerPortalUser) {
			setCustomerPortalUser(true)
		} else {
			setCustomerPortalUser(false)
		}
	}, [playbookRbacResponse])

	const actionButtons = useMemo(() => {
		return {
			primaryBtnLabel: "Learn More",
			primaryBtnAction: () => {
				dispatch(setGlobalStates({ showWelcomePlaybookPopup: true }))

			}
		}
	}, [])

	const gettingStartedDonutChartOptions = useMemo(() => {
		return {
			colors: ['var(--template-toggle-bg-color)', 'var(--getting-started-donut-chart-light-color)',],
			chart: {
				type: 'pie',
				height: 120,
				animation: false,
				events: {
					render: function () {
						const centerX = this.plotWidth / 2;
						const centerY = this.plotHeight / 2;
						const divLeft = centerX - 2;
						const divTop = centerY;
						const customDiv = document.createElement('div');
						customDiv.innerHTML = '<div style="font-size:13px; font-weight: 600; font-family:"Urbanist">' + gettingStartedDetails?.overallProgress ? (gettingStartedDetails?.overallProgress)?.toFixed(1) + '%' : '0%' + '</div>';
						customDiv.style.position = 'absolute';
						customDiv.style.left = divLeft + 'px';
						customDiv.style.top = divTop + 'px';
						customDiv.id = 'overall-progress'
						customDiv.key = 'overall-progress' + gettingStartedDetails?.overallProgress
						const existingDiv = document.getElementById('overall-progress');
						if (existingDiv) {
							existingDiv.parentNode.removeChild(existingDiv);
						}
						if (this?.container) {
							this.container.appendChild(customDiv);
						}

					},
				},

			},
			title: {
				text: ''
			},
			tooltip: {
				useHTML: true,
				backgroundColor: 'var(--sk-heading-color)',
				style: {
					padding: 0,
					color: 'var(--sk-heading-color)'
				},
				outside: true,
				shadow: false,
				borderWidth: 0,
				//  followPointer: false,
				// className:'playbook__getting__started__tooltip',
				formatter: () => {
					const progressData = gettingStartedDetails?.playbookModel?.progress;
					return (
						"<div class='playbook__getting__started__tooltip' style='min-width:150px;max-width:150px;'>" +
						"<div class='text'>Planned End date: " + moment(progressData?.plannedEnd).format('DD MMM') + "</div>" +
						"<div class='text'>Total Tasks: " + progressData?.numberOfTasks + "</div>" +
						"<div class='text'>Complete: " + progressData?.completedTasks + "</div>" +
						"<div class='text'>Overdue: " + progressData?.overdueTasks + "</div></div>"
					);
				}
			},
			legend: {
				enabled: false,
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					startAngle: 360,
					cursor: 'pointer',
					shadow: false,
					animation: false,
					dataLabels: {
						enabled: false,
						distance: -30,
						formatter: function () {
							if (this?.colorIndex === 1) {
								return '<span>' + gettingStartedDetails?.overallProgress?.toFixed(1) + '%</span>'
							}

						},
					},
					showInLegend: true
				}
			},
			series: [
				{
					name: '',
					colorByPoint: true,
					innerSize: '75%',
					data: (gettingStartedDetails && gettingStartedDetails?.playbookModel && gettingStartedDetails?.playbookModel?.progress && gettingStartedDetails?.playbookModel?.progress) ?
						[
							{
								x: 'Completed tasks',
								name: 'Completed tasks',
								y: gettingStartedDetails?.playbookModel?.progress?.completedTasks
							},
							{
								x: 'Incomplete tasks',
								name: 'Incomplete tasks',
								y: gettingStartedDetails?.playbookModel?.progress?.incompleteTasks
							},
						] : []
				}
			]
		};
	}, [gettingStartedDetails]);

	Highcharts.setOptions({
		chart: {
			style: {
				fontFamily: 'var(--sk-font-urbanist-family)'
			}
		}
	});

	Highcharts.merge(true, gettingStartedDonutChartOptions, {
		plotOptions: {
			pie: {
				size: '150%'
			},
		}
	});





	return (
		<>
			<aside className="main-sidebar">
				<div className={props?.open ? `${styles.sidenav} side_nav_bar` : `${styles.sidenavClosed} sidenavClosed side_nav_bar`} onMouseOver={e => { isSettingScreen && props?.actionsDashboard?.updateOverlayStatus(true) }} onMouseLeave={e => { isSettingScreen && props?.actionsDashboard?.updateOverlayStatus(false) }}>
					<div className={` ${styles["sidenav__sk_header"]}`}>
						<Link to={"*"} className={!isSettingScreen ? styles.headerLogo : ""}>
							{
								localStorage.getItem("CustomTheme") ? 
									!isSettingScreen ? <div className={styles["sidenav__sklogo"]} /> : <div className={styles["sidenav__closed_sklogo"]} />
								:
									HWUser.orgType() === "playbookStandalone" ? 
									(!isSettingScreen) ? <div className={styles["sidenav__sklogo_playbookStandalone"]} /> : <div className={styles["sidenav__closed_sklogo_playbookStandalone"]} />
									:
									!isSettingScreen ? <div className={styles["sidenav__sklogo"]} /> : <div className={styles["sidenav__closed_sklogo"]} />
							}
							</Link>
						{showIcon ? <button className={styles.menuBtn} onClick={props?.actionsDashboard.toggleOpen}>
							{!isSettingScreen ? <span className={props?.open ? styles.menuBtn_img : styles.menuBtn_img_rotate}></span> : ''}
						</button> : <button className={styles.menuBtn}>&nbsp;</button>}
					</div>
					<div key={"sidebar-" + forceRefreshId} className={`${styles["sidenav__sideItemWrap"]} sideItemWrap`}>
						{SideBarRoutes(playbookRbackScope(["CustomerPortalUser:Enabled"]), props?.open).filter(prop => prop.isEnabled).map((item, routeIndex) => {
							if (item.redirect) return null;
							if (item.isClickable) return <Link to={item.path} key={"sidebar" + item.path} onClick={e => {
								item.onClick && item.onClick(e);
								handleOnClick(e, item.name);
							}}>

								{item?.proFeature && item?.tooltipText && !item?.hideTooltipText ?
									<UiLabel showToolTipWithHtml={true} length={0} placement="right" tooltipClass={"sidebar__pro__info__tooltip"} label={item?.tooltipText}>
										<div key={"side-bar-route-key-" + routeIndex} className={((
											isActive === item.name || hoverTab === item.name) && item.isClickable) ? `${styles.sideitem} ${styles["sidenav--activeTab"]} activeTab` : styles.sideitem} ref={myRef.current?.current} onClick={(e) => {
												if (item?.name === 'Welcome to SmartPlaybooks' && item?.isEnabled && !props?.open) {
													dispatch(setGlobalStates({ showWelcomePlaybookPopup: true }))
												}
											}}>
											{props?.open ?
												<i className={item.className} /> :
												<>
													{/* <SkTooltip className={"sidenav__icon__tooptip__" + item.className} placement="top" title={item.name}> */}
														<i className={item.className} />
													{/* </SkTooltip> */}
													{item?.proFeature && <div className="collapsed__pro__icon"></div>}

												</>
											}
											{props?.open &&
												<div className={styles["sidenav__upgrade__wrap"]}>
													{item?.proFeature &&
														<span className={styles["sidenav__iconName"]}>{item.name}</span>
													}

													{!customerPortalUser && item?.proFeature &&

														<div className="pro__icon__wrap"><div className="pro__icon"></div>
															<div className="pro__feature__text">
																PRO
															</div>
														</div>

													}
												</div>
											}

										</div>
									</UiLabel>
									:

									<div key={"side-bar-route-key-" + routeIndex} className={((
										isActive === item.name || hoverTab === item.name) && item.isClickable) ? `${styles.sideitem} ${styles["sidenav--activeTab"]} activeTab` : styles.sideitem} ref={myRef.current?.current} onClick={(e) => {
											if (item?.name === 'Welcome to SmartPlaybooks' && item?.isEnabled && !props?.open) {
												dispatch(setGlobalStates({ showWelcomePlaybookPopup: true }))
											}
										}}>
										{props?.open ?
											<i className={item.className} /> :
											<>
												<SkTooltip className={"sidenav__icon__tooptip__" + item.className} placement="top" title={item.name}>
													<i className={item.className} />
												</SkTooltip>
												{item?.proFeature && <div className="collapsed__pro__icon"></div>}

											</>
										}
										{props?.open &&
											<div className={styles["sidenav__upgrade__wrap"]}>
												{item?.proFeature && item?.tooltipText && !item?.hideTooltipText ?

													<span className={styles["sidenav__iconName"]}>{item.name}</span>

													: <span className={styles["sidenav__iconName"]}>{item.name}</span>
												}

												{!customerPortalUser && item?.proFeature &&

													<div className="pro__icon__wrap"><div className="pro__icon"></div>
														<div className="pro__feature__text">
															PRO
														</div>
													</div>

												}

											</div>
										}

									</div>
								}


							</Link>;
							else return (
								item?.proFeature && item?.tooltipText && !item?.hideTooltipText ?

									<UiLabel showToolTipWithHtml={true} length={0} placement="right" tooltipClass={"sidebar__pro__info__tooltip"} label={item?.tooltipText}>
										<div key={"side-bar-route-key-" + routeIndex} className={((isActive === item.name || hoverTab === item.name) && item.isClickable) ? `${styles.sideitem} ${styles["sidenav--activeTab"]} activeTab` : styles.sideitem} ref={myRef.current?.current}
										>
											{props?.open ?
												<>
													<i className={item.className} style={{ opacity: "0.3", cursor: "default" }} />
												</> :
												<>
													
													{/* <SkTooltip className={"sidenav__icon__tooptip__" + item.className} placement="top" title={item?.name}> */}
														<i className={item.className} style={{ opacity: "0.3" }} />
													{/* </SkTooltip> */}

													{item?.proFeature && <div style={{ opacity: "0.3", cursor: "default" }} className="collapsed__pro__icon"></div>}

												</>
											}
											{props?.open &&
												<div className={styles["sidenav__upgrade__wrap"]}>
													{item?.proFeature && item?.tooltipText && !item?.hideTooltipText ?


														<span style={{ opacity: "0.3" }} className={styles["sidenav__iconName"]}>{item.name}</span>


														: <span style={{ opacity: "0.3" }} className={styles["sidenav__iconName"]}>{item.name}</span>
													}
													{item?.proFeature &&
														<UiLabel tooltipClass={"sidebar__pro__info__disabled__tooltip"} showToolTipWithHtml={true} length={0} placement="right" label={item?.tooltipText}>
															<div className="pro__icon__wrap" style={{ opacity: "0.3", cursor: "default" }}><div className="pro__icon"></div>
																<div className="pro__feature__text">
																	PRO</div>
															</div>
														</UiLabel>}

												</div>
											}

										</div>
									</UiLabel>
									:
									<div key={"side-bar-route-key-" + routeIndex} className={((isActive === item.name || hoverTab === item.name) && item.isClickable) ? `${styles.sideitem} ${styles["sidenav--activeTab"]} activeTab` : styles.sideitem} ref={myRef.current?.current}
									>
										{props?.open ?
											<>
												<i className={item.className} style={{ opacity: "0.3", cursor: "default" }} />
											</> :
											<>
												<SkTooltip className={"sidenav__icon__tooptip__" + item.className} placement="top" title={item?.name}>
													<i className={item.className} style={{ opacity: "0.3" }} />
												</SkTooltip>

												{item?.proFeature && <div style={{ opacity: "0.3", cursor: "default" }} className="collapsed__pro__icon"></div>}

											</>
										}
										{props?.open &&
											<div className={styles["sidenav__upgrade__wrap"]}>
												{item?.proFeature && item?.tooltipText && !item?.hideTooltipText ?

													<UiLabel tooltipClass={"sidebar__pro__info__disabled__tooltip"} showToolTipWithHtml={true} length={0} placement="right" label={item?.tooltipText}>
														<span style={{ opacity: "0.3" }} className={styles["sidenav__iconName"]}>{item.name}</span>

													</UiLabel>
													: <span style={{ opacity: "0.3" }} className={styles["sidenav__iconName"]}>{item.name}</span>
												}
												{item?.proFeature &&
													<UiLabel tooltipClass={"sidebar__pro__info__disabled__tooltip"} showToolTipWithHtml={true} length={0} placement="right" label={item?.tooltipText}>
														<div className="pro__icon__wrap" style={{ opacity: "0.3", cursor: "default" }}><div className="pro__icon"></div>
															<div className="pro__feature__text">
																PRO</div>
														</div>
													</UiLabel>}

											</div>
										}

									</div>
							)


						})}
					</div>
					{/* {(HWUser.orgType() === "playbookStandalone") && getGlobalStates().noOfLogin > 0 && getGlobalStates().noOfLogin <= 5 && props?.open &&
						<div className="sidebar__notification">
							<div className="sidebar__notification-content">
								<h6 className="sidebar__notification-header">
									Welcome to SmartPlaybooks

								</h6>

								<p className="sidebar__notification-text second__paragraph">
									Digitize your org wide best
									practices and SOPs by creating
									your own Playbook library.
									Reach out to us should you
									need any help!
								</p>
							</div>

							<div className="sidebar__notification-footer">
								<SKSmartUiBtns  {...actionButtons} className={'smart__ui__btns'} />
							</div>
						</div>
					} */}

					{/* getting started box */}
					{HWUser?.premiumFeature()?.GettingStarted === 'Enable' && !disableGettingStartedTab && props?.open &&
						<div className="sidebar__notification">
							<div className="sidebar__notification-content">
								<div className="sidebar__notification-header__wrap">
									<div className="sidebar__notification-header">
										Getting Started
									</div>

									{gettingStartedDetails && gettingStartedDetails?.playbookModel && gettingStartedDetails?.playbookModel?.progress && gettingStartedDetails?.playbookModel?.progress &&
										<div className="getting__started__progress__indicator">{gettingStartedDetails?.playbookModel?.progress?.completedTasks} / {gettingStartedDetails?.playbookModel?.progress?.numberOfTasks}</div>
									}
								</div>
								<p className="sidebar__notification-text second__paragraph">
									'Getting Started' Playbook - Your Onboarding Companion. Keep ticking off those tasks to take this process to closure
								</p>
							</div>
							{gettingStartedDetails && gettingStartedDetails?.playbookModel && gettingStartedDetails?.playbookModel?.progress && gettingStartedDetails?.playbookModel?.progress &&
								<div className="sidebar__notification-footer" style={{ marginTop: '0px' }}>
									<HighchartsReact key={'getting-started-graph' + forceRefreshId} highcharts={Highcharts} options={gettingStartedDonutChartOptions} />
								</div>
							}
						</div>
					}
					<p className={props?.open ? 'sidebar_build_version' : `${styles.sidenavClosed} sidebarclosed_build_version`}>Stage 1.0.3.09</p>
				</div>
				<Snackbar
					anchorOrigin={{ vertical, horizontal }}
					open={showNotification}
					className="sidebar_snackbar"
					autoHideDuration={6000}
					action={
						<div className="smart__snackbar__cross" onClick={e => dispatch(setGlobalStates({ showNotification: false }))
						}></div>
					}
					message={notificationMsg}
					sx={{ bottom: { xs: '100px !important', sm: 0 } }}
					TransitionComponent={'SlideTransition'}
				/>

				{showUndoToaster &&

					<Snackbar
						className="sidebar__snack"
						sx={{
							backgroundColor: '#FFF0F0',
							bottom: { xs: '90px !important', sm: 0 }
						}}
						anchorOrigin={{ vertical, horizontal }}
						open={true}
						autoHideDuration={6000}
						action={
							<div className='custom__notification__with__undo__button__wrap'>
								<div className='notification_row-container'>
									<div className='notification_row-description'>
										{showUndoToasterMessage}</div>
									<div className='notification_row-button'  onClick={()=>{ toasterUndoBtnClick && toasterUndoBtnClick()}}>
										<div className='notification_row-button-icon'></div>
										<span className='notification_row-button-text'>Undo</span>
									</div>
									<div className='notification_row-cross_icon' onClick={()=>{ toasterUndoCrossClick && toasterUndoCrossClick()}}></div>
								</div>
							</div>
						}
						TransitionComponent={'SlideTransition'}
					/>
				}
			</aside>

			{/* {getGlobalStates()?.showWelcomePlaybookPopup && (
				<SmartModal className="welcome__playbooks__modal" {...welcomeSmartPlaybooksPopup}>
					<WelcomeSmartPlaybooksBanner />
				</SmartModal>
			)} */}
		</>
	)
}

export default SideBar;

