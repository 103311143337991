import HWLocalStorage from './HWLocalStorage';
import moment from 'moment';
import defaultUserImage from '../assets/images/group_9588.png'
import { updateSDKUserAttributes } from './HWIframeBridge';
class HWUser {

	static _platformName = process.env.REACT_APP_PLATFORM_NAME ? process.env.REACT_APP_PLATFORM_NAME : null;
	static platformName = () => {
		return this._platformName;
	}

	static user = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (!platformUser) { return null; }
		return platformUser.user;
	}

	static userLoggedInRole = () =>{
		let platformUser = HWLocalStorage.load("platform-currentUser");
		let role = platformUser?.userRoles?.find(x => x.preferredRole ===  true);
		return role;
	}

	static scaleScreen = () => {
		const userData = this.user();
		if (userData && userData.scaleScreen !== undefined) {
			return userData.scaleScreen;
		}
		return true;
	}

	static isLoggedIn = () => {
		return window.localStorage.getItem("platform-currentUser") && true;
	}

	static appName = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (!platformUser) { return null; }
		let appName = platformUser.apps[0].appName
		return appName;
	}
	static lowbandwidth = () => {
		const userData = this.user();
		if (userData && userData.userId) { return userData.lowBandwidthConfig; }
		return null;
		// const data = {
		//     apiTimeOutInSec: 2,
		//     bandwidthCheckIntervalInSec: 3,
		//     enabled: true,
		//     messageBufferTimeInSec: 300,
		//     messageCoolingTimeInSec: 20, 
		//     messageTimeoutInSec: 10
		// }
		// return data;
	}
	//  This is only for demo. Please remove this.
	static powerbiAccessToken = () => {
		const userData = this.user();
		if (userData && userData.powerbiAccessToken) { return userData.powerbiAccessToken; }
		return null;
	}

	static isPowerBiReportEnabled = () => {
		const userData = this.user();
		if (userData && userData.showPowerBiReport) { return userData.showPowerBiReport; }
		return false;
	}

	static powerBiReportDeeplinkForReportName = (reportName) => {
		try {
			const userData = this.user();
			if (userData && userData.powerBIReportDeeplinkByName && userData.powerBIReportDeeplinkByName[reportName]) {
				return window.origin + userData.powerBIReportDeeplinkByName[reportName];
			}
		} catch (error) { 
			// console.log(error);
		 }
		return false;
	}

	static isImpersonatedUser = () => {
		var impersonatedUserStatus = localStorage.getItem('impersonated-user-status');
		return (impersonatedUserStatus && impersonatedUserStatus === "TRUE");
	}

	static setUserImpersonated = () => {
		localStorage.setItem('impersonated-user-status', "TRUE");
	}

	static resetUserImpersonated = () => {
		try { localStorage.removeItem('impersonated-user-status'); } catch (error) { 
			// console.log(error); 
		}
	}



	//  This is only for demo. Please remove this.
	// static powerbiAccessToken = () => {
	//     const userData = this.user();
	//     if (userData && userData.powerbiAccessToken) { return userData.powerbiAccessToken; }
	//     return null;
	// }

	static singleProduct = () => {
		const userData = this.user();
		if (userData && userData.singleProduct) { return userData.singleProduct; }
		return null;
	}

	static userId = () => {
		const userData = this.user();
		if (userData && userData.userId) { return userData.userId; }
		return null;
	}
	static roleId = () => {
		const userData = this.user();
		if (userData && userData?.rolePermissions?.roleId) { return userData?.rolePermissions?.roleId; }
		return null;
	}
	static emaiId = () => {
		const userData = this.user();
		if (userData && userData.emailId) { return userData.emailId; }
		return null;
	}
	static academyHelpStatus = () => {
		const userData = this.user();
		if (userData && userData.academyHelpStatus) { return userData.academyHelpStatus; }
		return null;
	}

	static isAdminUser = () => {
		const userLoggedInRole = this.userLoggedInRole();
		if (userLoggedInRole && userLoggedInRole.roleName) { return  userLoggedInRole.roleName === "Admin"; }
		return false;
	}
	static iscsmUser = () => {
		const userData = this.user();
		if (userData && userData.userRole) { return userData.userRole === "CustomerSuccessManager"; }
		return false;
	}

	static iscslUser = () => {
		const userData = this.user();
		if (userData && userData.userRole) { return userData.userRole === "CSMLeader"; }
		return false;
	}

	static orgPreType = () => {
		const userData = this.user();
		if(userData && userData.orgPreType) {
			return userData.orgPreType; 
		}
		return false;
	}

	static permissionLevelAdmin = () => { 
		const userData = this.user();
		if(userData && userData.permissionLevel) {
			return (userData.permissionLevel === "Admin"); 
		}
		return false;
	}

	static permissionLevelAdminOrLeader = () => { 
		const userData = this.user();
		if(userData && userData.permissionLevel) {
			return (userData.permissionLevel === "Leader" || userData.permissionLevel === "Admin"); 
		}
		return false;
	}

	static smartOrderTableConfiguration = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser?.orderViewModel) {
			return platformUser?.orderViewModel
		} else {
			return null
		}
	}

	static smartFinancialModelConfiguration = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser?.financeModel) {
			return platformUser?.financeModel
		} else {
			return null
		}
	}

	static isCSUser = () => {
		let csUsers = ["80beb093-a8fc-4341-bbfe-3f033d57d9a8", "039ab0a5-5018-4fc4-8cc0-4484a1ba8dc0", "d1607701-ba26-444e-a42c-ed0763c46fab", "fe61d35f-b670-480a-be08-9e847e67869a", "ece6a11f-765d-4c4f-9232-c27c6476882c"]
		return csUsers.indexOf(this.appId()) !== -1;
	}

	static isExecutiveUser = () => {
		const userData = this.user();
		if (userData && userData.userRole) { return userData.userRole === "Executive"; }
		return false;
	}

	static isCSMOrCSLUser = () => {
		const userData = this.user();
		if (userData && userData.userRole) { return userData.userRole === "CSMLeader" || userData.userRole === "CustomerSuccessManager"; }
		return false;
	}

	static isDemoAccount = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		return (platformUser && platformUser.multiLevelType === "DEMO_Account") || false;
	}

	static isUserDemoAccount = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		return (platformUser && platformUser.userAppPreferences && platformUser.userAppPreferences.multiLevelType === "DEMO_Account") || false;
	}

	static appId = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser.userAppPreferences) { return platformUser.userAppPreferences.appId; }
		return null;
	}
	static premiumFeature = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser?.user?.premiumFeature) { return platformUser?.user?.premiumFeature; }
		return null;
	}

	static checkproFeatureStatus = (val) => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser?.user?.premiumFeature) {
			let status = platformUser?.user?.premiumFeature[val];
			return status
			// return status === "Enable" ? true : status === "Disable" ? false: false;
		}
		return null;
	}

	static isPremiumFeatureEnable = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser.user?.premiumFeature) {
			return this.premiumFeatureStatus(platformUser.user?.premiumFeature);
		}
		return false;
	}

	static orgType = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser.user?.orgType) {
			return platformUser.user?.orgType;
		}
		return null;
	}

	static isSuccessPlanEnable = () => {
		let premiumFeature = this.premiumFeature();
		if (premiumFeature != null && premiumFeature.SuccessPlan === "Enable") {
			return true;
		}
		return false;
	}

	static dateFormat = () => {
		const userData = this.user();
		if (userData && userData.dateFormat) { return userData.dateFormat; }
		return "DD-MMM-YYYY";
	}

	static productOrService = () => {
		const userData = this.user();
		if (userData && userData.offering) { return userData.offering; }
		return "product";
	}

	static platformEnvironment = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (!platformUser || !platformUser.user) { return null; }
		return platformUser.user.platformEnvironment
	}

	static platformConfigured = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (!platformUser || !platformUser.userAppPreferences) { return []; }
		return platformUser.userAppPreferences.platformsConfigured || []
		// if (!platformUser ) { return null; }
		// return platformUser.userPlatformPreference
	}

	static userProfileImageURL = () => {
		if (this.user() && this.user().profileImageURL) {
			return this.user().profileImageURL;
		} else { return defaultUserImage; }  //"https://platform.smartkarrot.com/assets/images/user.jpg"; }
	}

	static accessToken = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser.accessToken) { return platformUser.accessToken; }
		return null;
	}

	static refreshToken = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser.refreshToken) { return platformUser.refreshToken; }
		return null;
	}

	static roleType = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser.refreshToken) { return platformUser.roleType; }
		return null;
	}

	static updateTokens(accessToken, refreshToken) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser) { return null; }
			platformUser.accessToken = accessToken;
			platformUser.refreshToken = refreshToken;
			HWLocalStorage.save(platformUser, "platform-currentUser");
		} catch (error) { }
	}

	static acccessTokenExpTimeInEpochSec = () => {
		try {
			let token = this.accessToken();
			return JSON.parse(atob(token.split('.')[1])).exp;
		} catch (error) { }
		return null;
	}

	static refreshTokenExpTimeInEpochSec = () => {
		try {
			let token = this.refreshToken();
			return JSON.parse(atob(token.split('.')[1])).exp;
		} catch (error) { }
		return null;
	}

	static orgId() {
		if (this.user() && this.user().organizationId) { return this.user().organizationId; }
		return null;
	}

	static orgImageURL() {
		if (this.user() && this.user().logoImageURL) {
			return this.user().logoImageURL.split("?")[0];
		} else if (window.localStorage.getItem("sk-newOrgImage")) { return window.localStorage.getItem("sk-newOrgImage").split("?")[0]; }
	}

	static orgName() {
		if (this.user() && this.user().organizationName) {
			return " " + this.user().organizationName;
		} else { return ""; }
	}

	static orgCreateDate() {
		if (this.user() && this.user().organizationCreateDate) {
			return this.user().organizationCreateDate;
		} else { return null; }
	}

	static orgPreferences() {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser.orgPreferences) { return platformUser.orgPreferences; }
		return null;
	}

	static allOrganizations() {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser.allOrganizations) { return platformUser.allOrganizations; }
		return null;
	}

	static isAllySite() {
		return process.env.REACT_APP_IS_ALLY_DOMAIN === "true";
	}

	static isSmartKarrotAllySite() {
		// return (process.env.REACT_APP_IS_ALLY_DOMAIN === "true") && (["ally.smartkarrot.com","stage-ally.smartkarrot.com"].indexOf(window.location.host) !== -1);
		return (process.env.REACT_APP_IS_ALLY_DOMAIN === "true") && (["ally.smartkarrot.com", "stage-ally.smartkarrot.com", "localhost:3000"].indexOf(window.location.host) !== -1);
	}

	static allyOrgName() {
		return this.isAllySite() && this.orgName();
	}

	static isSandboxAndProdSwitchEnabled() {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		return platformUser && platformUser.user && platformUser.user.sandboxAndProdSwitchEnabled;
	}

	static isAsanaIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }

			// if (HWUser.isDemoAccount()) {
			//     return true;
			// }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "Asana")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static isAsanaIntegratedForThisUser(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }

			// if (HWUser.isDemoAccount()) {
			//     return true;
			// }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			const integration = platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "Asana")[0];
			return (integration.status === "Verified") && integration.asanaUserId && true;
		} catch (error) { }
		return false;
	}

	static isJIRAIntegratedForThisUser(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }

			// if (HWUser.isDemoAccount()) {
			//     return true;
			// }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			const integration = platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "JIRA")[0];
			return (integration.status === "Verified") && integration.jiraUserId && true;
		} catch (error) { }
		return false;
	}

	static getAsanaUserId(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }

			// if (HWUser.isDemoAccount()) {
			//     return null;
			// }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			const integration = platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "Asana")[0];
			return (integration.status === "Verified") ? integration.asanaUserId : null;
		} catch (error) { }
		return null;
	}
	static getJiraUserId(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }

			// if (HWUser.isDemoAccount()) {
			//     return null;
			// }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			const integration = platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "JIRA")[0];
			return (integration.status === "Verified") ? integration.jiraUserId : null;
		} catch (error) { }
		return null;
	}

	static isAsanaPopUpShown() {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let popUpList = platformUser && platformUser.thirdPartyIntegrationPopUp;
			if (!platformUser) { return null; }
			let isAsanaPopUpTriggered = popUpList && popUpList.length > 0 && popUpList.filter(i => i.integrationName === 'ASANA')[0].popUpDisplayed;
			return isAsanaPopUpTriggered;
		} catch (error) { }
		return false;
	}

	static isJiraIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "JIRA")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static isJiraTaskIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "JIRA" && (k.skIntegrationScopes && k.skIntegrationScopes.indexOf("skJiraSoftwareIntegration") !== -1))[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static isSKTaskIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "SK_TASK")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	// Is gsuite integrated for this user.
	static isZoomIntegratedForThisUser = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (!platformUser || !platformUser.user || !platformUser.user.integrations) { return false; }
		return this.isZoomIntegrated() && (platformUser.user.integrations.indexOf("Zoom") !== -1);
	}


	static showBanner = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser.user?.premiumFeature) {
			return this.showBannerStatus(platformUser.user?.premiumFeature);
		}
		return false;
	}

	static showBannerStatus = (premiumFeatures) => {
		if (premiumFeatures.SmartKonversationBanner === "true") {
			return true
		}
		return false;
	}


	static premiumFeatureStatus = (premiumFeatures) => {
		if (premiumFeatures?.SmartKonversation === "Enable") {
			return true;
		}
		return false;
	}

	static isZoomIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "Zoom")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static isZoomConfigure = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		const userData = this.user();
		if (userData && userData.userRole) { return platformUser?.userAppPreferences?.showProductBModule }
		return false;
	}

	static isSKStripeIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "STRIPE")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	// static isSKZohoIntegrated(forAppId) {
	//     try {
	//         let platformUser = HWLocalStorage.load("platform-currentUser");
	//         let appIdToCheck = forAppId ? forAppId : this.appId();
	//         if (!platformUser) { return null; }
	//         return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "ZOHO")[0].status) === "Verified";
	//     } catch (error) { }
	//     return false;
	// }
	static isSKZohoBookIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = this.appId();
			if (!platformUser) { return null; }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "ZOHO_BOOK")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}
	static isSKZohoDeskIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = this.appId();
			if (!platformUser) { return null; }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "ZOHO DESK")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static isSKIntercomIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = this.appId();
			if (!platformUser) { return null; }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "INTERCOM")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static isSKZohoMailIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = this.appId();
			if (!platformUser) { return null; }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "ZOHO_CALENDAR_MAIL")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	// static isZohoEmailIntegratedForApp() {
	//     try {
	//         let platformUser = HWLocalStorage.load("platform-currentUser");
	//         let appIdToCheck = this.appId();
	//         if (!platformUser) { return null; }
	//         const integrationDeatils = platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "ZOHO")[0];
	//         return (integrationDeatils.status === "Verified") && integrationDeatils.skIntegrationScopes && (integrationDeatils.skIntegrationScopes.indexOf("ZohoEmailAndCalendar") !== -1);
	//     } catch (error) { }
	//     return false;
	// }

	static isZohoEmailIntegratedForUser = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (!platformUser || !platformUser.user || !platformUser.user.integrations) { return false; }
		return platformUser.user.integrations.indexOf("ZOHO_CALENDAR_MAIL") !== -1;
	}

	static isSKSalesForceIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "Salesforce")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}
	static isChargebeeIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "Chargebee")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}
	static isTeamworkDeskIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "TeamworkDesk")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static isTeamSupportIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "TEAMSUPPORT")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static isSKTicketIntegrated = (forAppId) => {
		// let platformUser = HWLocalStorage.load("platform-currentUser");
		// if (!platformUser || !platformUser.user || !platformUser.user.integrations) { return false; }
		// return platformUser.user.integrations.indexOf("OrgTicket") !== -1;
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			// console.log("appIntegrationCheck",platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "OrgTicket")[0].status);
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "OrgTicket")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static isJiraPopUpShown() {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let popUpList = platformUser && platformUser.thirdPartyIntegrationPopUp;
			if (!platformUser) { return null; }
			let isJiraPopUpTriggered = popUpList && popUpList.length > 0 && popUpList.filter(i => i.integrationName === 'JIRA')[0].popUpDisplayed;
			return isJiraPopUpTriggered;
		} catch (error) { }
		return false;
	}

	// Is gsuite integrated for this user.
	static isGsuiteIntegrated = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (!platformUser || !platformUser.user || !platformUser.user.integrations) { return false; }
		return platformUser.user.integrations.indexOf("G_SUITE") !== -1;
	}

	// If any one has integrated gsuite for app.
	static isGsuiteIntegratedForApp = () => {
		try {
			let appId = HWUser.appId();
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser || !appId) { return false; }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			let configDetails = platformUser.apps.filter(i => i.appId === appId)[0].externalIntegrations.filter(k => k.integration === "G_SUITE")[0];
			return configDetails.status === "Verified";
		} catch (error) { }
		return false;
	}

	static isGSuitePopUpShown() {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let popUpList = platformUser && platformUser.thirdPartyIntegrationPopUp;
			if (!platformUser) { return null; }
			let isGSuitePopUpTriggered = popUpList && popUpList.length > 0 && popUpList.filter(i => i.integrationName === 'G_SUITE')[0].popUpDisplayed;
			return isGSuitePopUpTriggered;
		} catch (error) { }
		return false;
	}

	static isOutlookIntegrated = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (!platformUser || !platformUser.user || !platformUser.user.integrations) { return false; }
		return platformUser.user.integrations.indexOf("MICROSOFT_365") !== -1;
	}

	// If any one has integrated gsuite for app.
	static getOutlookIntegratedThirdPartyDetails = () => {
		try {
			let appId = HWUser.appId();
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser || !appId) { return false; }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			let thirdPartyUser = platformUser.apps.filter(i => i.appId === appId)[0].externalIntegrations.filter(k => k.integration === "MICROSOFT_365")[0];
			// console.log(thirdPartyUser)
			return thirdPartyUser
			// return configDetails.status === "Verified";
		} catch (error) { }
		return false;
	}
	static getGsuiteIntegratedThirdPartyDetails = () => {
		try {
			let appId = HWUser.appId();
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser || !appId) { return false; }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			let thirdPartyUser = platformUser.apps.filter(i => i.appId === appId)[0].externalIntegrations.filter(k => k.integration === "G_SUITE")[0];
			// console.log(thirdPartyUser)
			return thirdPartyUser
			// return configDetails.status === "Verified";
		} catch (error) { }
		return false;
	}
	static isOutlookIntegratedForApp = () => {
		try {
			let appId = HWUser.appId();
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser || !appId) { return false; }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			let configDetails = platformUser.apps.filter(i => i.appId === appId)[0].externalIntegrations.filter(k => k.integration === "MICROSOFT_365")[0];
			return configDetails.status === "Verified";
		} catch (error) { }
		return false;
	}

	static isOutlookPopUpShown() {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let popUpList = platformUser && platformUser.thirdPartyIntegrationPopUp;
			if (!platformUser) { return null; }
			let isOutlookPopUpTriggered = popUpList && popUpList.length > 0 && popUpList.filter(i => i.integrationName === 'MICROSOFT_365')[0].popUpDisplayed;
			return isOutlookPopUpTriggered;
		} catch (error) { }
		return false;
	}

	// Is gsuite integrated for this user.
	static isHubSpotIntegrated = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (!platformUser || !platformUser.user || !platformUser.user.integrations) { return false; }
		return platformUser.user.integrations.indexOf("HUBSPOT") !== -1;
	}

	// If any one has integrated HubSpot for app.
	static isHubSpotIntegratedForApp = () => {
		try {
			let appId = HWUser.appId();
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser || !appId) { return false; }
			let configDetails = platformUser.apps.filter(i => i.appId === appId)[0].externalIntegrations.filter(k => k.integration === "HUBSPOT")[0];
			return configDetails.status === "Verified";
		} catch (error) { }
		return false;
	}

	static isHubSpotPopUpShown() {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let popUpList = platformUser && platformUser.thirdPartyIntegrationPopUp;
			if (!platformUser) { return null; }
			let isHubSpotPopUpTriggered = popUpList && popUpList.length > 0 && popUpList.filter(i => i.integrationName === 'HUBSPOT')[0].popUpDisplayed;
			return isHubSpotPopUpTriggered;
		} catch (error) { }
		return false;
	}

	static isFreshdeskIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			// if (HWUser.isDemoAccount()) {
			//     return true;
			// }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "Freshdesk")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static isZendeskIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			// if (HWUser.isDemoAccount()) {
			//     return true;
			// }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "ZENDESK")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}
	static isTeamworkDeskIntegrated(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			let appIdToCheck = forAppId ? forAppId : this.appId();
			if (!platformUser) { return null; }
			// if (HWUser.isDemoAccount()) {
			//     return true;
			// }
			return (platformUser.apps.filter(i => i.appId === appIdToCheck)[0].externalIntegrations.filter(k => k.integration === "TeamworkDesk")[0].status) === "Verified";
		} catch (error) { }
		return false;
	}

	static zendeskCredentials(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser) { return null; }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			let configDetails = platformUser.apps.filter(i => i.appId === forAppId)[0].externalIntegrations.filter(k => k.integration === "ZENDESK")[0];
			let isFreshdeskVerified = configDetails.status === "Verified";
			return isFreshdeskVerified ? configDetails : null;
		} catch (error) { }
		return null;
	}

	static zendeskDomainName(forAppId) {
		let credentials = this.zendeskCredentials(forAppId);
		return (credentials && credentials.zendeskDomainName) || null;
	}

	static zendeskTicketEsclatedFieldId(forAppId) {
		let credentials = this.zendeskCredentials(forAppId);
		return (credentials && credentials.zendeskEscalatedFieldId) || null;
	}

	static freshdeskDomainName(forAppId) {
		let credentials = this.freshdeskCredentials(forAppId);
		return (credentials && credentials.freshdeskDomainName) || null;
	}

	static freshdeskCredentials(forAppId) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser) { return null; }
			// console.log(platformUser.apps.filter(i => i.appId === forAppId)[0]);
			let configDetails = platformUser.apps.filter(i => i.appId === forAppId)[0].externalIntegrations.filter(k => k.integration === "Freshdesk")[0];
			let isFreshdeskVerified = configDetails.status === "Verified";
			return isFreshdeskVerified ? configDetails : null;
		} catch (error) { }
		return null;
	}

	static updateFreshdeskCredentials(forAppId, freshdeskDomainName, freshdeskAPIKey) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser) { return null; }
			let appDetails = platformUser.apps.filter(i => i.appId === forAppId)[0];

			if (!appDetails.externalIntegrations) { appDetails.externalIntegrations = []; }
			let freshdeskIntegration = appDetails.externalIntegrations.filter(k => k.integration === "Freshdesk")[0];
			if (!freshdeskIntegration) {
				freshdeskIntegration = { "integration": "Freshdesk", "status": "Verified", "freshdeskDomainName": freshdeskDomainName, "freshdeskAPIKey": freshdeskAPIKey };
				appDetails.externalIntegrations.push(freshdeskIntegration);
			} else {
				freshdeskIntegration.status = "Verified";
				freshdeskIntegration.freshdeskDomainName = freshdeskDomainName;
				freshdeskIntegration.freshdeskAPIKey = freshdeskAPIKey;
			}
			HWLocalStorage.save(platformUser, "platform-currentUser");
		} catch (error) { }
	}

	static updateZendeskCredentials(forAppId, zendeskCode) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser) { return null; }
			let appDetails = platformUser.apps.filter(i => i.appId === forAppId)[0];
			if (!appDetails.externalIntegrations) { appDetails.externalIntegrations = []; }
			let zendeskIntegration = appDetails.externalIntegrations.filter(k => k.integration === "Zendesk")[0];
			if (!zendeskIntegration) {
				zendeskIntegration = { "integration": "Zendesk", "status": "Verified", "zendeskCode": zendeskCode };
				appDetails.externalIntegrations.push(zendeskIntegration);
			} else {
				zendeskIntegration.status = "Verified";
				zendeskIntegration.zendeskCode = zendeskCode;
			}
			HWLocalStorage.save(platformUser, "platform-currentUser");
		} catch (error) { }
	}

	static updateOrgNameLocal(orgName) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser) { return null; }
			platformUser.organizationName = orgName;
			platformUser.user.organizationName = orgName;
			HWLocalStorage.save(platformUser, "platform-currentUser");
		} catch (error) { }
	}

	static updateOrgImageLocal(orgImageURL) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser) { return null; }
			platformUser.organizationImageURL = orgImageURL;
			platformUser.user.logoImageURL = orgImageURL;
			HWLocalStorage.save(platformUser, "platform-currentUser");
		} catch (error) { }
	}

	static isOrgCreatedWithIn90Days() {
		try {
			let date = moment(new Date(this.orgCreateDate()));
			return (moment(new Date()).diff(date, "days") < 90);
		} catch (error) { }
		return false;
	}

	static userRole = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser.user && platformUser.user.userRole) { return platformUser.user.userRole; }
		return null;
	}

	static updateUserProfileData = (profileData) => {
		let profileKey = 'platform-currentUser';
		let currentUser = HWLocalStorage.load(profileKey);
		currentUser.user = profileData["user"];
		currentUser.apps = profileData["apps"];
		HWLocalStorage.save(currentUser, profileKey);
		updateSDKUserAttributes();
	}

	static addToUserIntegrations(integrationType) {
		try {
			let platformUser = HWLocalStorage.load("platform-currentUser");
			if (!platformUser || !platformUser.user) { return null; }
			platformUser.user.integrations = [...(platformUser.user.integrations || []), integrationType];
			HWLocalStorage.save(platformUser, "platform-currentUser");
		} catch (error) { }
	}

	static revenueUnit = () => {
		return localStorage.getItem("sk-revenue-unit") || "ARR"
	}

	static updateRevenueUnit = (unit) => {
		localStorage.setItem("sk-revenue-unit", unit)
	}
	static listOfApps = () => {
		let profileKey = 'platform-currentUser';
		let currentUser = HWLocalStorage.load(profileKey);
		// let appsList = currentUser?.apps?.map(eachApp => {return {value:eachApp.appId,label:eachApp.appName}}) 
		let appsList = [{ value: currentUser?.userAppPreferences?.appId, label: currentUser?.userAppPreferences?.appName }]
		return appsList;
	}

	static showCustomChurnByProduct = () => {
		const userData = this.user();
		if (userData && userData.customChurnByProduct) {
			return userData.customChurnByProduct;
		}
		return false;
	}

	static VendorPortal = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser && platformUser?.user?.premiumFeature && platformUser?.user?.premiumFeature?.VendorPortal) { return platformUser?.user?.premiumFeature?.VendorPortal; }
		return null;
	}

	static loggedInUserType = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if(platformUser?.user?.userType) return platformUser?.user?.userType;
		return null;
	}

	static isSubtaskEnabled = () => {
		let platformUser = HWLocalStorage.load("platform-currentUser");
		if (platformUser?.user?.premiumFeature?.Subtask) { return platformUser?.user?.premiumFeature?.Subtask === "Enable" }
		return null;
	}

	static isDependentTaskEnabled = () => {
		let premiumFeature = this.premiumFeature();
		if (premiumFeature?.DependentTask === "Enable") return true;
		return false;
	}

	static isTimeBasedTaskEnabled = () => {
		let premiumFeature = this.premiumFeature();
		if (premiumFeature?.TimeBasedTask === "Enable") return true;
		return false;
	}

	static isTimeBasedRuleEnabled = () => {
		let premiumFeature = this.premiumFeature();
		if (premiumFeature?.TimeBasedRule === "Enable") return true;
		return false;
	}

	static landingScreen = () => {
		if (this.user() && this.user().landingScreen)
			return this.user().landingScreen;

		return null;
	}
}

export default HWUser