import { store } from "../index";
import HWUser from "../../HWLibrary/HWUser";
import { apiCall } from "../api";
import { skPlayBookActions } from "../constants/actionType";
import hwAxios from "../../HWLibrary/HWAxios";
import cache from "../../SkSmartUtil/InMemoryCache";
import skApiService from "../../SkApiService";
import axios from "axios";


export const getSmartPlayBookStates = () => {
	return store.getState()?.smartPlayBookReducer;
}

export const playbookRbackScope = (featureScopes)=>{
	let token = store.getState()?.smartPlayBookReducer?.playbookRbacResponse?.token;
	if(!token){return false};
	const playbookRbackScope = atob(token).split(" ");
	if (!featureScopes) { return false; }
	return featureScopes && featureScopes.some((scope) => playbookRbackScope.some((featureScope) =>  featureScope === scope));
}

export const setSmartPlayBookStates = (obj) => async dispatch => {
	dispatch({
		type: skPlayBookActions?.SET_SMART_PLAY_BOOK_STATES, payload: obj
	})
}

// fetch phase reference data
export const fetchPhaseOptions = () => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId() };
	dispatch(setSmartPlayBookStates({ isPlayBookLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/reference-data/phase",
			method: "GET",
			payload: request,
			addFilter: true
		});
		if (response?.status === 200) {
			let list = response?.data?.data?.map(item => { return ({ id: item?.id, value: item?.name, label: item?.name }) });
			dispatch(setSmartPlayBookStates({ isPlayBookLoading: false }))
			dispatch({
				type: skPlayBookActions?.FETCH_PHASE_OPTIONS,
				payload: { assignmentRulePhaseOptionsList: list }
			});
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}

};

// fetch product reference data 
export const fetchProductOptionsAtOrgLevel = () => async dispatch => {
	let request = {}
	request.params = { orgId: HWUser.orgId(),appId: HWUser.appId(),userId: HWUser.userId() };
	dispatch(setSmartPlayBookStates({ isPlayBookLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/products",
			method: "GET",
			payload: request,
			addFilter: true
		});
		if (response?.status === 200) {
			let list = response?.data?.productList?.map(item => { return ({ id: item?.id, value: item?.id, label: item?.name }) });
			dispatch(setSmartPlayBookStates({ isPlayBookLoading: false }))
			dispatch({
				type: skPlayBookActions?.FETCH_REFERENCE_PRODUCTS,
				payload: { productDataForOrganization: list }
			});
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}

};

// Fetch external users
export const fetchExternalUsers = (forceInvalidate) => async dispatch => {
	dispatch(setSmartPlayBookStates({ isPlayBookLoading: true }))
	try {
		let users = [];
		skApiService.fetchExternalUsers(null, forceInvalidate).then(response => {
			let data = response.data ? response.data : [];
			data.forEach(item => {

				if(item?.activationStatus !== 'Inactive') {
					users.push({
						id: item?.id,
						value: item?.emailId,
						label: item?.name,
						imageUrl: item?.imageUrl,
						emailId: item?.emailId,
						activationStatus: item?.activationStatus
					})
				}
			})
			users = users.sort((a, b) => a?.label?.localeCompare(b?.label));
			dispatch({
				type: skPlayBookActions?.FETCH_USER_OPTIONS,
				payload: { portalUsersReferenceList: users }
			})
		})
		dispatch(setSmartPlayBookStates({ isPlayBookLoading: false }))
	} catch (error) {

		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}


// fetch audience reference data 
export const fetchAudienceOptions = () => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId() };
	dispatch(setSmartPlayBookStates({ isPlayBookLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/reference-data/email-audience",
			method: "GET",
			payload: request,
			addFilter: true
		});
		if (response?.status === 200) {
			let list = response?.data?.data?.map(item => { return ({ id: item?.id, value: item?.name, label: item?.name }) });
			dispatch(setSmartPlayBookStates({ isPlayBookLoading: false }))
			dispatch({
				type: skPlayBookActions?.FETCH_AUDIENCE_OPTIONS,
				payload: { emailAudienceOptionsList: list }
			});
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}

};

// fetch data for verify audience pop up
export const fetchVerifyAudienceData = (playbookId, taskId,searchText, token) => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(),playbookId: playbookId, playbookTaskId:taskId, nextToken:token, searchText: searchText };
	dispatch(setSmartPlayBookStates({ isPlayBookLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/live/email-audience",
			method: "GET",
			payload: request,
			addFilter: true
		});

		if (response?.status === 200) {
		
			dispatch({
				type: skPlayBookActions?.FETCH_VERIFY_AUDIENCE_DATA,
				payload: { smartVerifyAudienceData: response?.data, isPlayBookLoading: false }
			});
			return 'Audience data fetched successfully.'
		}
		else {
			return 'Unable to fetch audience data.'
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}

};

// update audience data for verify audience pop up
export const editAudienceForLivePlaybooks = (taskId, ids) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), playbookTaskId:taskId, excludedAudienceIds:ids};
	dispatch(setSmartPlayBookStates({  blockConfirmationPopup: true}))
	try { 
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/live/email-audience",
			method: "PUT",
			payload: request,
		});
		if (response?.status === 200) {
			dispatch({
				type: skPlayBookActions?.EDIT_VERIFY_AUDIENCE_DATA,
				payload: { blockConfirmationPopup: false },
			});
			return 'Verify audience details updated successfully.'
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false, blockConfirmationPopup: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}

export const fetchTaskAssigneeOptions = () => async dispatch => {
	dispatch(setSmartPlayBookStates({ isPlayBookLoading: true }))
	try {
		let users = [];
		skApiService.fetchUsers().then(response => {
			let data = response.data ? response.data : [];
			data.forEach(item => {
				users.push({
					id: item?.userId,
					value: item?.userId,
					label: item?.username,
					imageUrl: item?.imageUrl,
					email: item?.email,
					data: {
						...item
					}
				})
			})
			users = users.sort((a, b) => a?.label?.localeCompare(b?.label));
			dispatch({
				type: skPlayBookActions?.FETCH_ASSIGNEE_OPTIONS,
				payload: { taskAssigneeOption: users, isLoading: false }
			})
		})
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ errorMessage: '', isError: false }))
		}, 2000)

	}
}

// fetch template  data
export const fetchTemplateOptions = (type) => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(),type:type };
	dispatch(setSmartPlayBookStates({ isLadingTemplates: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/reference-data/template",
			method: "GET",
			payload: request,
			addFilter: true
		});
		if (response?.status === 200) {
			let list = response?.data?.data?.map(item => { return ({ id: item?.id, value: item?.name, label: item?.name }) });
			dispatch({
				type: skPlayBookActions?.FETCH_TEMPLATE_OPTIONS,
				payload: { referenceTemplatesList: list }
			});
			dispatch(setSmartPlayBookStates({ isLadingTemplates: false }))
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}

};

// ASSIGNMENT RULES===================================

// fetch assignment rules
export const fetchAssignmentRules = (req) => async dispatch => {
	
	if(localStorage.getItem("auth")){
			req = req ? {...req, gtmatrics :true } : {gtmatrics: true}
	}
	dispatch(setSmartPlayBookStates({isPlayBookLoading: true }))
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), ...req};
	
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/assignment-rule/dashboard",
			method: "GET",
			payload: request,
			addFilter: true
		});
		if (response?.status === 200) {
			dispatch({
				type: skPlayBookActions?.FETCH_ASSIGNMENT_RULES,
				payload: {
					smartAssignmentRulesList: response?.data?.data,
					assignmentRuleToken: ''
				}
			});	
		}
		dispatch(setSmartPlayBookStates({ isPlayBookLoading: false }))

	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false, fetchApiForAssignmentRuleListingScreen: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}

};

export const fetchAssignmentRulesForTemplate = (templateId) =>  async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), playbookTemplateId: templateId};
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/assignment-rule/template-rule",
			method: "GET",
			payload: request,
			addFilter: true
		});
		if (response?.status === 200) {	
			return response?.data?.data
		}

	} catch (error) {

	}
}

// fetch template data for assignment rules
export const fetchTemplateDataAssignmentRules = (id) => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(),templateId: id};
	dispatch(setSmartPlayBookStates({ fetchingTemplateData: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/template/preview",
			method: "GET",
			payload: request,
			addFilter: true
		});
		if (response?.status === 200) {
			dispatch(setSmartPlayBookStates({ smartAssignmentRuleTemplateData: response?.data }))

			dispatch(setSmartPlayBookStates({ fetchingTemplateData: false }));
			return 'Trigger template data fetched successfully.'
		} else {
			return 'Unable to fetch trigger template data.'
		}

	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, fetchingTemplateData: false}))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}

};

// fetch history logs for assignment rules
export const fetchHistoryLogsForAssignmentRules = (id, token, order) => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), assignmentRuleId: id, nextToken:token, sortOrder:order  };
	dispatch(setSmartPlayBookStates({ isPlayBookLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/assignment-rule/history-log",
			method: "GET",
			payload: request,
			addFilter: true
		});
		if (response?.status === 200) {
			dispatch({
				type: skPlayBookActions?.FETCH_HISTORY_LOGS_ASSIGNMENT_RULE,
				payload: {
					smartAssignmentRuleHistoryLogsList: response?.data?.logs,
					smartAssignmentRuleHistoryLogsNextToken: response?.data?.logs?.nextToken
				}
			});
			dispatch(setSmartPlayBookStates({ isPlayBookLoading: false }))
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}

};

// post assignment rule
export const postNewAssignmentRule = (data) => async dispatch => {
	dispatch(setSmartPlayBookStates({ /*isPlayBookLoading: true ,*/ blockConfirmationPopup: true}))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + '/playbook/assignment-rule',
			method: 'POST',
			payload: data,
		});
		if (response?.status === 200) {
			dispatch(setSmartPlayBookStates({ /*isPlayBookLoading: false,*/  blockConfirmationPopup: false }))
			dispatch({
				type: skPlayBookActions?.ADD_ASSIGNMENT_RULE,
				payload: { playBookSuccessMsg: 'Saved' },
			});
			return 'Trigger saved successfully.'
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true,  blockConfirmationPopup: false,playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}

// delete assignment rule
export const deleteAssignmentRule = (assignmentRuleId) => async dispatch => {
	let request = {};
	request.params = { assignmentRuleId: assignmentRuleId };
	dispatch(setSmartPlayBookStates({ isPlayBookLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/assignment-rule",
			method: 'DELETE',
			payload: request,
		});
		if (response?.status === 200) {
			dispatch(setSmartPlayBookStates({ playBookSuccessMsg: 'Deleted' }))
			dispatch({
				type: skPlayBookActions?.DELETE_ASSIGNMENT_RULE,
				payload: { isPlayBookLoading: false },
			});
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}

// fetch single assignment rule 
export const fetchSingleAssignmentRule = (assignmentRuleId) => async dispatch => {
	let request = {};
	request.params = {
		orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),
		assignmentRuleId: assignmentRuleId
	};
	dispatch(setSmartPlayBookStates({ isPlayBookLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/assignment-rule",
			method: "GET",
			payload: request,
			addFilter: true
		});
		dispatch({
			type: skPlayBookActions?.FETCH_SINGLE_ASSIGNMENT_RULE,
			payload: { isPlayBookLoading: false },
		})
		return response?.data;
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}

// on edit assignment rule 
export const editAssignmentRule = (data) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartPlayBookStates({ /*isPlayBookLoading: true,*/ blockConfirmationPopup: true}))
	try { 
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/assignment-rule",
			method: "PUT",
			payload: request,
		});
		if (response?.status === 200) {
			dispatch({
				type: skPlayBookActions?.EDIT_ASSIGNMENT_RULE,
				payload: { /*isPlayBookLoading: false,*/ blockConfirmationPopup: false },
			});
			return 'Trigger updated successfully.'
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false, blockConfirmationPopup: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}

// ASSIGNMENT RULES===================================

const parseOutComeDats = (data) => {
	return (data || []).map((item, index) => {
		return { value: item.id, valid: true, label: item.name ,id:item.id,imageUrl: item?.imageUrl,
			email: item?.email,...item}
	})
}
const  sortOpt = (data) => {
	data && data.sort((a, b) => {
	return ((a.label) || " ").localeCompare(((b.label) || " "));
})
return data;
}

const fetchCategoryRefrenceDataCache = () => {
	const cacheKey      = HWUser.userId() ;
	const fetchFunction = () => {
		let url     = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/reference-data/outcome";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
		aConfig.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()}
		return hwAxios.get(url, aConfig);
	}
	return cache.get(cacheKey, cache.CacheModule.outcomeRefData, fetchFunction);
}

// fetch template category refrence  data 
export const fetchCategoryRefrenceData = () => async dispatch => {
	let request = {};
	request.params = {
		orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()
	};
	dispatch(setSmartPlayBookStates({ isOutcomeRefData: true }))
	try {
		fetchCategoryRefrenceDataCache().then(response => {
			dispatch({
				type: skPlayBookActions?.FETCH_OUTCOME_OPTIONS,
				payload: { outcomeRefData:  sortOpt(parseOutComeDats(response?.data?.data)) ,templateLabels:response?.data?.labels},
			});
			dispatch(setSmartPlayBookStates({ isOutcomeRefData: false }))
		})
	} catch (error) {
		dispatch({
			type: skPlayBookActions?.FETCH_OUTCOME_OPTIONS,
			payload: { outcomeRefData: [] },
		});
	}
}

const fetchRoleRefrenceDataCache = () => {
	let url     = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/reference-data/role";
	let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
	aConfig.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()}
	return hwAxios.get(url, aConfig);
}
// fetch template Role refrence  data 
export const fetchRoleRefrenceData = () => async dispatch => {
	let request = {};
	request.params = {
		orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()
	};
	dispatch(setSmartPlayBookStates({ isRoleRefData: true }))
	try {
		fetchRoleRefrenceDataCache().then(response => {
			dispatch({
				type: skPlayBookActions?.FETCH_ROLE_OPTIONS,
				payload: { roleRefData: sortOpt(parseOutComeDats(response?.data?.data)) },
			});
			dispatch(setSmartPlayBookStates({ isRoleRefData: false }))
		})
	} catch (error) {
		dispatch({
			type: skPlayBookActions?.FETCH_ROLE_OPTIONS,
			payload: { roleRefData: [] },
		});
	}
}

const fetchTypeRoleRefrenceDataCache = () => {
	const cacheKey      = HWUser.userId() ;
	const fetchFunction = () => {
		let url     = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/reference-data/type-role";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
		aConfig.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()}
		return hwAxios.get(url, aConfig);
	}
	return cache.get(cacheKey, cache.CacheModule.usersTypeRoleData, fetchFunction);
}



// fetch template Role refrence  data 
export const fetchAccontTypeRoleRefrenceData = () => async dispatch => {
	let request = {};
	request.params = {
		orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()
	};
	dispatch(setSmartPlayBookStates({ isRoleRefData: true }))
	try {
		fetchTypeRoleRefrenceDataCache().then(response => {
			let palyloadData = {loggedInUserRoleType:response?.data?.loggedInUserRoleType,playbookTypes:response?.data?.playbookTypes};
			if(response?.data?.data[0]?.type === "Account"){
				palyloadData.accountTypeRoleRefData = sortOpt(parseOutComeDats(response?.data?.data[0]?.roles))
			}
			if(response?.data?.data[1]?.type === "NonAccount"){
				palyloadData.nonAccountTypeRoleRefData = sortOpt(parseOutComeDats(response?.data?.data[1]?.roles))
			}
			dispatch({
				type: skPlayBookActions?.FETCH_ROLE_OPTIONS,
				payload: { ...palyloadData},
			});
			dispatch(setSmartPlayBookStates({ isRoleRefData: false }))
		})
	} catch (error) {
		dispatch({
			type: skPlayBookActions?.FETCH_ROLE_OPTIONS,
			payload: { roleRefData: [] },
		});
	}
}

const fetchuserRefrenceDataCache = (forceRefresh) => {
	const cacheKey      = HWUser.userId() ;
	const fetchFunction = () => {
		let url     = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/reference-data/user";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
		aConfig.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()}
		return hwAxios.get(url, aConfig);
	}
	return cache.get(cacheKey, cache.CacheModule.usersData, fetchFunction, forceRefresh);
}

// fetch template User refrence  data 
export const fetchUsereRefrenceData = (forceRefresh) => async dispatch => {
	let request = {};
	request.params = {
		orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()
	};
	dispatch(setSmartPlayBookStates({ isUserRefData: true }))
	try {
		fetchuserRefrenceDataCache(forceRefresh).then(response => {
			dispatch({
				type: skPlayBookActions?.FETCH_USER_OPTIONS,
				payload: { userRefData: sortOpt(parseOutComeDats(response?.data?.data)) },
			});
		})
		dispatch(setSmartPlayBookStates({ isUserRefData: false }))
	} catch (error) {
		dispatch({
			type: skPlayBookActions?.FETCH_USER_OPTIONS,
			payload: { roleRefData: [] },
		});
	}
}

// Create template
export const createTemplate = (data) => async dispatch => {
	dispatch(setSmartPlayBookStates({ isCreatingTemplate: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + '/playbook/template',
			method: 'POST',
			payload: data,
		});
		if (response?.status === 200) {
			dispatch(setSmartPlayBookStates({ isCreatingTemplate: false }))
			dispatch({
				type: skPlayBookActions?.ADD_TEMPLATE,
				payload: { playBookSuccessMsg: 'Saved' },
			});
			return 'Template saved successfully'
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isCreatingTemplate: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}

export const updateTemplate = (data) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartPlayBookStates({ isCreatingTemplate: true}))
	try { 
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + '/playbook/template',
			method: "PUT",
			payload: request,
		});
			dispatch({
				type: skPlayBookActions?.UPDATE_TEMPLATE,
				payload: { isCreatingTemplate: false ,updateTemplateData:response?.date},
			});
			return response?.data
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false, blockConfirmationPopup: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false,isCreatingTemplate:false }))
		}, 2000)
		return error?.response?.data?.message
	}
}
export const duplicatetemplate = (data) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartPlayBookStates({ isCreatingTemplate: true}))
	try { 
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + '/playbook/template/duplicate',
			method: "PUT",
			payload: request,
		});
			dispatch({
				type: skPlayBookActions?.UPDATE_TEMPLATE,
				payload: { isCreatingTemplate: false ,updateTemplateData:response?.date},
			});
			return 'Template updated successfully.'
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false, blockConfirmationPopup: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}


// fetch template outCome Data
export const fetchTemplatetOutComesData = (searchText) => async dispatch => {
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),searchText:searchText };
	dispatch(setSmartPlayBookStates({ isLoadingOtcomeData: true, deafeaultOpenedOutcomeIds: null }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/template/category",
			method: "GET",
			payload: request,
			addFilter: true
		});
		dispatch(setSmartPlayBookStates({ isLoadingOtcomeData: false,outcomes:response?.data?.data,deafeaultOpenedOutcomeIds:[response?.data?.data[0]?.outcomeId] }))
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isLoadingOtcomeData: false, outcomes: [], deafeaultOpenedOutcomeIds: [] }))
	}
}

// fetch playbook outCome Data
export const fetchPlaybookOutComesData = (searchTxt) => async dispatch => {
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),searchText:searchTxt };
	dispatch(setSmartPlayBookStates({ isLoadingOtcomeData: true, deafeaultPlaybookOpenedOutcomeIds: null }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/live/dashboard",
			method: "GET",
			payload: request,
			addFilter: true
		});
		dispatch(setSmartPlayBookStates({ isLoadingOtcomeData: false,playbookDashboardData:response?.data,playbookOutcomes:response?.data?.outcomes,deafeaultPlaybookOpenedOutcomeIds:[response?.data?.outcomes[0]?.outcomeId] }))
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isLoadingOtcomeData: false, playbookOutcomes: [],playbookDashboardData:null, deafeaultPlaybookOpenedOutcomeIds: [] }))
	}
}

// Get Single Playbook data 
export const getSinglePlaybookData = (data) => async dispatch => {			
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartPlayBookStates({ isCreatingTemplate: true}))
	try { 
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + '/playbook/live',
			method: "GET",
			payload: request,
			addFilter:true,
		});
			dispatch({
				type: skPlayBookActions?.UPDATE_TEMPLATE,
				payload: { isCreatingTemplate: false ,updatePlaybookData:response?.data},
			});
			return response?.data
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false, blockConfirmationPopup: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false,isCreatingTemplate:false }))
		}, 2000)
		return error?.response?.data?.message
	}
}

export const updatePlaybook = (data) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartPlayBookStates({ isCreatingTemplate: true}))
	try { 
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + '/playbook/live',
			method: "PUT",
			payload: request,
		});
			dispatch({
				type: skPlayBookActions?.UPDATE_TEMPLATE,
				payload: { isCreatingTemplate: false ,updatePlaybookData:response?.data},
			});
			return response?.data
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false, blockConfirmationPopup: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false,isCreatingTemplate:false }))
		}, 2000)
		return error?.response?.data?.message
	}
}

// Create playbook tak
export const addPlaybookTask = (data) => async dispatch => {
	dispatch(setSmartPlayBookStates({ isCreatingTemplate: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + '/playbook/live/task',
			method: 'POST',
			payload: data,
		});
		if (response?.status === 200) {
			dispatch(setSmartPlayBookStates({ isCreatingTemplate: false }))
			dispatch({
				type: skPlayBookActions?.ADD_TEMPLATE,
				payload: { playBookSuccessMsg: 'Saved' },
			});
			return 'Template saved successfully'
		}
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isCreatingTemplate: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}
//update playbook task status

export const updatePlaybookTaskStatus = (data, callingFrom) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartPlayBookStates({ isCreatingTemplate: callingFrom === 'markAsComplete' ? false : true}))
	try { 
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + '/playbook/live/task/complete',
			method: "PUT",
			payload: request,
		});
			dispatch({
				type: skPlayBookActions?.UPDATE_TEMPLATE,
				payload: { isCreatingTemplate: false ,updatePlaybookData:response?.date},
			});
			return 'Template updated successfully.'
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, isPlayBookLoading: false, blockConfirmationPopup: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false,isCreatingTemplate:false }))
		}, 2000)
		return error?.response?.data?.message
	}
}

// fetch template data for assignment rules
export const fetchPlaybookTouchpointData= (data) => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(),...data};
	dispatch(setSmartPlayBookStates({ fetchingTemplateData: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/touchpoints",
			method: "GET",
			payload: request,
		});
		if (response?.status === 200) {
			dispatch(setSmartPlayBookStates({ playbookLinkTouchpointData: response?.data }))
			dispatch(setSmartPlayBookStates({ fetchingTemplateData: false }));
			return 'Trigger template data fetched successfully.'
		} else {
			return 'Unable to fetch trigger template data.'
		}

	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, fetchingTemplateData: false}))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}

};

const fetchRbacScope = (feature) => {
	const userLoggedInRole = HWUser.userLoggedInRole();
	const cacheKey      = HWUser.userId() + feature;
	const fetchFunction = () => {
		let url     = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/rbac/feature-scope";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = { 
			orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),roleId:userLoggedInRole?.roleId, feature:feature
		}
		return axios.get(url, aConfig);
	}
	return cache.get(cacheKey, cache.CacheModule.userRbacScope, fetchFunction);
}

// fetch  playbook RBAC scope change 
export const fetchPlaybookRbacScope = (feature) => async dispatch => {
	const userLoggedInRole = HWUser.userLoggedInRole();
	let request = {};
	request.params = {
		orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),roleId:userLoggedInRole?.roleId, feature:feature
	};
	dispatch(setSmartPlayBookStates({ isLoadinPlaybookRbac: true }))
	try {
		fetchRbacScope(feature).then(response => {
			dispatch({
				type: skPlayBookActions?.FETCH_USER_OPTIONS,
				payload: { playbookRbacResponse: response?.data },
			});
			dispatch(setSmartPlayBookStates({ isLoadinPlaybookRbac: false }))
		})
	} catch (error) {
		dispatch({
			type: skPlayBookActions?.FETCH_USER_OPTIONS,
			payload: { roleRefData: [] },
		});
	}
}

export const fetchClientUsersReferenceList = () => async dispatch => {
	let request = {};
	request.params = {
		orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),
	};
	dispatch(setSmartPlayBookStates({ isUserRefData: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/reference-data/client-user",
			method: "GET",
			payload: request,
			addFilter: true
		});
		if (response?.status === 200) {
			dispatch(setSmartPlayBookStates({ clientUsersReferenceList: sortOpt(parseOutComeDats(response?.data?.data)) }));
			dispatch(setSmartPlayBookStates({ isUserRefData: false }))
		} 
		
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isUserRefData: false }))	
	}
}

// Analytics APIs==========================================
// 01. Currently Active Widget
export const fetchAnalyticsWidget = (view) => async dispatch => {
	let request = {};
	request.params = {
		orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),
		tableView: view 
	};
	dispatch(setSmartPlayBookStates({ playbookAnalyticsLoader: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/analytics",
			method: "GET",
			payload: request,
			addFilter: true
		});
		let data;
		if (view === 'User') {
			data = response?.data?.userDetail
		} else {
			data = response?.data?.accountDetail
		}
		dispatch({
			type: skPlayBookActions?.FETCH_PLAYBOOK_ANALYTICS,
			payload: {
				playbookAnalyticsCurrentlyActiveWidget: response?.data?.currentlyActive,
				playbookAnalyticsStatusWidget: response?.data?.playbookStatus,
				playbookAnalyticsUserGrowthWidget: response?.data?.userGrowth,
				playbookAnalyticsTableWidget: data,
				playbookAnalyticsOverview: response?.data?.overview,
			},
		});
		dispatch(setSmartPlayBookStates({ playbookAnalyticsLoader: false }))
	} catch (error) {
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, playbookAnalyticsLoader: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}

export const fetchTemplateInUse = (status, nextToken) => async dispatch => {
	let request = {};
	request.params = {
		orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),
		status: status,
		nextToken: nextToken,
	}
	dispatch(setSmartPlayBookStates({  playbookAnalyticsTemplateLoader: true }))
	try{
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/analytics-new",
			method: "GET",
			payload: request,
			addFilter: true
		});
		let data;
		data = response?.data;
		dispatch({
			type: skPlayBookActions?.FETCH_PLAYBOOK_ANALYTICS,
			payload: {
				playbookAnalyticsTemplate: nextToken ? [...getSmartPlayBookStates().playbookAnalyticsTemplate, ...data?.templates] : data?.templates,
				playbookAnalyticsNextToken: data?.nextToken
			},
		});
		dispatch(setSmartPlayBookStates({  playbookAnalyticsTemplateLoader: false }))
	} catch(error){
	}
}

export const exportFromWorkspace = (req) => async dispatch =>  {
	let request = {};
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), ...req };
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/export/excel",
			method: "GET",
			payload: request,
			addFilter:true,
		});
		return response?.data?.message
	} catch (error) {
		return "error"
	}

}

// Analytics APIs==========================================

// const fetchCachedGettingstartedDetails = () => {
// 	const cacheKey      = HWUser.userId() ;
// 	const fetchFunction = () => {
// 		let url     = window.resolveRegion(process.env.REACT_APP_GETTING_STARTED_URL) 
// 		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
// 		aConfig.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()}
// 		return hwAxios.get(url, aConfig);
// 	}
// 	return cache.get(cacheKey, cache.CacheModule.gettingStartedDetails, fetchFunction);
// }


export const fetchGettingStartedData = (view) => async dispatch => {	let request = {
	orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),

	};
	dispatch(setSmartPlayBookStates({ gettingStartedLoader: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_GETTING_STARTED_URL),
			method: "POST",
			payload: request,
			addFilter: false
		});

		const overallProgress = response?.data?.overallProgress;
		const completionStatus=response?.data?.completionStatus;
		// console.log("=============== ", overallProgress, completionStatus)
		dispatch({
			type: skPlayBookActions?.SET_SMART_PLAY_BOOK_STATES,
			payload: {disableGettingStartedTab: overallProgress === 100 || completionStatus==="Completed" ? true: false},
		});
		dispatch({
			type: skPlayBookActions?.FETCH_PLAYBOOK_GETTING_STARTED_DETAILS,
			payload: {gettingStartedDetails: response?.data,},
		});
		// if (overallProgress === 100 || completionStatus==="Completed") {
		// 	window.localStorage.setItem('showGettingStarted', false);
		// }
	
		dispatch(setSmartPlayBookStates({ gettingStartedLoader: false }))
		return response
	} catch (error) {
		if (error?.response?.data?.message === "No Getting Started Playbook") {
			dispatch({
				type: skPlayBookActions?.SET_SMART_PLAY_BOOK_STATES,
				payload: {disableGettingStartedTab:  true},
			});
			
				window.localStorage.setItem('showGettingStarted', false);
		
		}
		// if (error?.response?.data?.message === "Getting Started Playbook is completed") {
		// 	dispatch({
		// 		type: skPlayBookActions?.SET_SMART_PLAY_BOOK_STATES,
		// 		payload: {disableGettingStartedTab:  true},
		// 	});
			
		// 		// window.localStorage.setItem('showGettingStarted', false);
		
		// }
	
		dispatch(setSmartPlayBookStates({ isPlayBookError: true, playBookErrorMessage: error?.response?.data?.message, playbookAnalyticsLoader: false }))
		setTimeout(() => {
			dispatch(setSmartPlayBookStates({ playBookErrorMessage: '', isPlayBookError: false }))
		}, 2000)
	}
}
