import { Tooltip, makeStyles } from '@material-ui/core';
import { cloneDeep, debounce } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import whiteInfoIcon from "../../src/assets/phoenix/info_white.png";
import access from '../HWLibrary/AccessControl';
import HWUser from '../HWLibrary/HWUser';
// import TaskListsView from '../SKSmartTask/SmartTaskDashboard/TaskListsView';
import SKSearch from '../SkSmartUtil/SKSmartSearch';
import { TableSkeleton } from '../SkSmartUtil/Skeleton/SkeletonCard';
import SmartConfirmModal from '../SkSmartUtil/SmartConfirmModal/SmartConfirmModal';
// import SkToggle from '../SkSmartUtil/SmartToggle/SkToggle';
import UiLabel from '../SkSmartUtil/UiLabel/UiLabel';
// import SmartCalendar from '../SmartCalendar/SmartCalendar';
// import SmartEmail from "../SmartEmail/SmartEmail";
import SmartFilterTab from '../SmartFilterTab/SmartFilterTab';
import smartFilterTokenService from '../SmartFilterTab/SmartFilterToken';
import { fetchAccountList, getGlobalStates } from '../store/reducers/skGlobalReducer';
import { fetchMails, getSmartMailState, setSmartMailState } from '../store/reducers/skMailReducer';
import { fetchAccontTypeRoleRefrenceData, fetchAssignmentRules, fetchCategoryRefrenceData, fetchClientUsersReferenceList, fetchExternalUsers, fetchGettingStartedData, exportFromWorkspace, fetchPlaybookOutComesData, fetchPlaybookRbacScope, fetchProductOptionsAtOrgLevel, fetchRoleRefrenceData, fetchTemplateOptions, fetchTemplatetOutComesData, fetchUsereRefrenceData, getSmartPlayBookStates, playbookRbackScope, setSmartPlayBookStates } from '../store/reducers/skPlaybooksReducer';
import { fetchSmartTasksList, fetchListViewTasks, fetchTaskAccountOptions, fetchTaskCategoryReferenceData, fetchTaskCollaboratorOptions, fetchTaskOutcomeOptions, fetchTaskProductOptions, fetchTaskWithCategories, fetchToggleViewPreference, getSmartTaskStates, setSmartTaskStates } from '../store/reducers/skTaskReducer';
import { fetchTochpointRefenceData, fetchTouchpointWithOutComesData, getSmartTochpointStates, setSmartTochpointStates, singleTouchpoint } from '../store/reducers/skTouchpointReducer';
import SkLoaderComponent from '../SkSmartUtil/SkLoader/SkLoaderComponent';
import skTouchpointservice from './TouchpointView/SkTouchpointservice';
// import SKGettingStartedDashboard from './SKGettingStartedDashboard/SKGettingStartedDashboard';
// import PlayBookAssignmentRulesGridTable from './SkPlayBook/AssignmentRules/PlayBookAssignmentRulesGridTable';
// import PlaybookAnalyticsDashboard from './SkPlayBook/PlaybookAnalytics/PlaybookAnalyticsDashboard';
// import ActivePlaybookDetails from './SkPlayBook/Summary/ActivePlaybookDetails';
// import PlayBookSummaryListingGridTable from './SkPlayBook/Summary/PlayBookSummaryListingGridTable';
// import PlayBookSummaryScreenDashboard from './SkPlayBook/Summary/PlayBookSummaryScreenDashboard';
// import PlayBookTemplateGridTable from './SkPlayBook/Templates/PlayBookTemplateGridTable';
// import TouchpointDashboard from './TouchpointView/TouchpointDashboard';
import TouchpointType from './TouchpointView/TouchpointType';
import './Workspace.css';
import '../SKSmartTask/SmartTask.css';
import {useNavigate} from "react-router-dom"
import { fetchFunctionData } from '../store/reducers/skConfigurationReducer';
import WorkspaceExternalUserJoyride from './WorkspaceExternalUserJoyride';
// import WorkspaceJoyride from './WorkspaceJoyride'

// const SmartConfirmModal = lazy(() => import('../SkSmartUtil/SmartConfirmModal/SmartConfirmModal'));
const SKGettingStartedDashboard = lazy(() => import('./SKGettingStartedDashboard/SKGettingStartedDashboard'));
const SmartCalendar = lazy(() => import("../SmartCalendar/SmartCalendar"));
const SmartEmail = lazy(() => import("../SmartEmail/SmartEmail"));
const TouchpointDashboard = lazy(() => import('./TouchpointView/TouchpointDashboard'));
const PlayBookSummaryScreenDashboard = lazy(() => import('./SkPlayBook/Summary/PlayBookSummaryScreenDashboard'));
const ActivePlaybookDetails = lazy(() => import('./SkPlayBook/Summary/ActivePlaybookDetails'));
const PlayBookSummaryListingGridTable = lazy(() => import('./SkPlayBook/Summary/PlayBookSummaryListingGridTable'));
const PlayBookTemplateGridTable = lazy(() => import('./SkPlayBook/Templates/PlayBookTemplateGridTable'));
const PlaybookAnalyticsDashboard = lazy(() => import('./SkPlayBook/PlaybookAnalytics/PlaybookAnalyticsDashboard'));
const SkToggle = lazy(() => import('../SkSmartUtil/SmartToggle/SkToggle'));
const TaskListsView = lazy(() => import('../SKSmartTask/SmartTaskDashboard/TaskListsView'));
const PlayBookAssignmentRulesGridTable = lazy(() => import('./SkPlayBook/AssignmentRules/PlayBookAssignmentRulesGridTable'));
const WorkspaceJoyride = lazy(() => import('./WorkspaceJoyride'));



function Workspace() {
	const togglePlaybookAnalyticsRef = useRef();
	const navigate = useNavigate()
	const useStyles = makeStyles((theme) => ({
		customTooltip: {
			backgroundColor: 'var(--sk-heading-color) !important',
			color: 'white !important',
			fontSize: '14px',
			fontFamily: 'var(--sk-font-urbanist-family)',
			boxShadow: '0px 9px 30px var(--sk-graph-popper)',
			borderRadius: '11px',
			padding: '8px !important',
			minWidth: '50px !important',
			maxWidth: '200px !important',
			fontWeight: '500',
			'& .MuiTooltip-arrow': {
				color: 'var(--sk-heading-color) !important'
			},
		},
	}));

	const classes = useStyles();
	const getSmartTochpointState = useSelector(getSmartTochpointStates);
	const { touchpointType,	touchpointTypeCount,
		touchpointActiveView } = getSmartTochpointState;
	const getSmartTaskState = useSelector(getSmartTaskStates);
	const getMailState = useSelector(getSmartMailState);
	const { taskAccountOptions, taskProductOptions, taskCollaboratorOptions, totalTaskCount, delayedTaskCount, activeTaskToggleView } = getSmartTaskState;
	const getGlobalState = useSelector(getGlobalStates);
	const { outcomeVisibleName, outcomeStatus } = getGlobalState;
	const getSmartPlayBookState = useSelector(getSmartPlayBookStates);
	const { playbookDashboardData, showPlaybookDetails, playbookRbacResponse, roleType, playbookPopoutRowData, expandedIds, activeOutcomeId, clientUsersReferenceList, disableGettingStartedTab, productDataForOrganization, taskTypeOption, taskNonAcccountTypeOption, taskOnlyOption } = getSmartPlayBookState;
	const [activeTab, setActiveTab] = useState(
		window.location.pathname.split('/').pop() === "workspace" ? 
		!disableGettingStartedTab && HWUser.premiumFeature()?.GettingStarted === 'Enable' ? 'getting_started' : 
		HWUser.landingScreen() === 'workspace:playbook_live' && (access?.playbook?.playbook() || access?.playbook?.summary()) ? 'playbooks_Summary' :
		(access?.smartTask?.list() ? 'timeline' : access?.playbook?.dashboard() ? 'Playbooks' : null) : 
		window.location.pathname.split('/').pop());
	const dispatch = useDispatch();
	const [inflowActiveTab, setInflowActiveTab] = useState();
	const [activeTemplateId, setActiveTemplateId] = useState(null);
	const [updateUserPreference, setUpdateUserPreference] = useState(false);
	const [outcomeId, setOutcomeId] = useState(null);
	const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);
	const [templateId, setTemplateId] = useState(null);
	const [summaryData, setSummaryData] = useState(null);
	const [assignmentRuleData,setAssignmentRuleData] = useState(null);
	const [emailIntegrated, setEmailIntegrated] = useState(true);
	const [searchText, setSearchText] = useState(null);
	const [activePlaybookView, setActivePlaybookView] = useState(null);
	const [forceRefresh, setforceRefresh] = useState(null);
	const [subscriptionId, setSubscriptionId] = useState(null);
	const [activeTemplateView, setActiveTemplateView] = useState(null);
	const [activePlaybookAnalyticsView, setActivePlaybookAnalyticsView] = useState(null);
	const [skSmartFilterToken, setSkSmartFilterToken] = useState(null);
	const [taskToggleOptionsList, setTaskToggleOptionsList] = useState([]);
	const [disableFilterPreferenceApiCall, setDisableFilterPreferenceApiCall] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [customerPortalEnabled, setCustomerPortalEnabled] = useState(playbookRbackScope(["CustomerPortalUser:Enabled"]));
	const [state, setStates] = useState({
	})
	const [activeTempateTab, setActiveTempateTab] = useState("template")
	const setStatesData = (updatedState) => {
		setStates(prevState => (
			{
				...prevState,
				...updatedState
			}
		))
	}

	// console.log("============ disableGettingStartedTab ", disableGettingStartedTab);
	// console.log("============ activeTab ", activeTab);
	// console.log("============ showGettingStarted ", window?.localStorage?.getItem("showGettingStarted"));

	const location = useLocation();
	const tabs = useMemo(() => {
		return [
			{ title: 'Getting Started', id: 'getting_started', primary: true, path: 'getting_started', isEnable: HWUser.premiumFeature()?.GettingStarted === 'Enable' && !disableGettingStartedTab, rootPath: "/dashboard/workspace/getting_started" },
			{
				title: 'Playbooks', id: 'Playbooks', primary: true,
				tabs: [
					{ title: 'Dashboard', id: 'PlayBooksDashboard', path: "playbook_analytics", isEnable: (access?.playbook?.dashboard()), rootPath: "/dashboard/workspace/playbook/PlayBooksDashboard" },
					{ title: 'Summary', id: 'playbooks_Summary', path: "playbook_live", isEnable: (access?.playbook?.playbook() || access?.playbook?.summary()), rootPath: "/dashboard/workspace/playbook/playbooks_Summary" },
					{ title: 'Triggers', id: 'PlayBooksAssignmentRules', path: 'playbook_assignment_rule', isEnable: (access?.playbook?.playbook() || access?.playbook?.rule()), rootPath: "/dashboard/workspace/playbook/PlayBooksAssignmentRules" },
					{ title: 'Templates', id: 'template', path: "playbook_template", isEnable: (access?.playbook?.playbook() || access?.playbook?.template()), rootPath: "/dashboard/workspace/playbook/template" },
				],

				isEnable: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.playbook?.playbook()), rootPath: !access?.playbook?.dashboard() ? "/dashboard/workspace/playbook/playbooks_Summary" : "/dashboard/workspace/playbook/PlayBooksDashboard"
			},
			{ title: 'Tasks', id: 'timeline', primary: true, path: 'task_list', isEnable: access?.smartTask?.list(), rootPath: "/dashboard/workspace/timeline" },
			{ title: 'Touchpoints', id: 'Touchpoints', path: 'touchpoint_list_view', primary: true, isEnable: access?.smartTouchpoint?.viewTab(), rootPath: "/dashboard/workspace/Touchpoints" },
			{
				title: 'Email', id: 'Email', primary: true, isEnable: (HWUser.isGsuiteIntegratedForApp() || HWUser.isOutlookIntegratedForApp() || HWUser.isZohoEmailIntegratedForUser()) && HWUser.roleType() !== "NonAccount" && access?.email?.view(), disableUserFilterPreference: true, rootPath: "/dashboard/workspace/email/Inbox",
				tabs: [
					{ title: 'Inbox', id: 'Inbox', isEnable: true, path: "email_inbox", rootPath: "/dashboard/workspace/email/Inbox" },
					{ title: 'Sent', id: 'Sent', isEnable: (HWUser.isGsuiteIntegratedForApp() || HWUser.isOutlookIntegratedForApp() || HWUser.isZohoEmailIntegratedForUser()) && HWUser.roleType() !== "NonAccount", path: "email_sent", rootPath: "/dashboard/workspace/email/Sent" }
				], path: "email"
			},
			{ title: 'Calendar', id: 'Calendar', primary: true, isEnable: (HWUser.isGsuiteIntegratedForApp() || HWUser.isOutlookIntegratedForApp() || HWUser.isZohoEmailIntegratedForUser()) && HWUser.roleType() !== "NonAccount" && access?.calendar?.view(), path: "calender", disableUserFilterPreference: true, rootPath: "/dashboard/workspace/Calendar" },
		];
	}, [disableGettingStartedTab])

	useEffect(() => {
		let path = 
			window.location.pathname.split('/').pop() === "workspace" ? 
			!disableGettingStartedTab && HWUser.premiumFeature()?.GettingStarted === 'Enable' ? 'getting_started' : 
			HWUser.landingScreen() === 'workspace:playbook_live' && (access?.playbook?.playbook() || access?.playbook?.summary()) ? 'playbooks_Summary' :
			(access?.smartTask?.list() ? 'timeline' : access?.playbook?.dashboard() ? 'Playbooks' : null) : 
			window.location.pathname.split('/').pop();
			
		let parentTab = null; let subTab = null;
		if (tabs.some(i => i.tabs && i.tabs.some(p => p.id === path))) {
			parentTab = tabs.filter(i => i.tabs && i.tabs.some(p => p.id === path))[0];
			subTab = parentTab.tabs && parentTab.tabs.filter(i => i.id === path)[0];
			setInflowActiveTab(subTab);
		} else if (tabs.some(i => i.id === path)) {
			parentTab = tabs.filter(i => i.id === path)[0];
			setInflowActiveTab(parentTab);
		}
		return () => {
			setSubscriptionId(null);
			dispatch(setSmartTaskStates({ activeTaskToggleView: null }));
	
		}
	}, [location, tabs, disableGettingStartedTab])

	const fetchtoggleview = useCallback((tab) => {
		setLoadingData(true)
		dispatch(fetchToggleViewPreference(tab?.path)).then((res) => {

			let featurePath = res?.featurePath;
			 if (featurePath === "playbook_analytics") {
				if (res.view !== activePlaybookAnalyticsView) {
					setactivePlaybookAnalyticsView(res.view)
				}
			} else if (featurePath === "playbook_template") {
				if (res.view !== activeTemplateView) {
					setactiveTemplateView(res.view)
				}

			} else if (featurePath === "playbook_live") {
				if (res.view !== activePlaybookView) {
					setactiveplaybookView(res.view)
				}

			}

		})
	}, [activePlaybookAnalyticsView, activePlaybookView, activeTaskToggleView, activeTemplateView, , dispatch])


	const onTabActive = useCallback((tab) => {

		if ((tab?.id === 'Outcome' || tab?.id === 'Function' || tab?.id === 'template' || tab?.id === 'playbooks_Summary' || tab?.id === 'PlayBooksDashboard') && (activeTab !== 'timeline' || activeTab !== 'Outcome' || activeTab !== 'Function' || activeTab !== 'template' || activeTab !== 'playbooks_Summary' || activeTab !== 'PlayBooksDashboard')) {
			fetchtoggleview(tab)
		} else {
			setSkSmartFilterToken(null)
		}
		if (tab?.id === 'getting_started') {
			setDisableFilterPreferenceApiCall(true);
		} else {
			setDisableFilterPreferenceApiCall(false);
		}
		let token = smartFilterTokenService.token;
		if (tab?.id === 'PlayBooksDashboard' && activeTab === 'playbooks_Summary' && token?.accountFilter?.accountIds?.length === 1) {
			smartFilterTokenService?.addAccounts([], ["AllAccounts"], true);
		}
		if ((activeTab?.id === tab?.id) || (tab?.id === activeTab)) { return; }
		setActiveTab(tab?.id)
		setSearchText(null);
		setIsSearchBarFocused(false);
		dispatch(setSmartMailState({ showPreview: false }))
		if (tab?.id !== getMailState?.activeTab) {
			dispatch(setSmartMailState({ activeTab: tab?.id }))
		}

		setSkSmartFilterToken(null)

	}, [activeTab, dispatch, getMailState?.activeTab, fetchtoggleview])

	const checkEmailIntegration = useCallback(() => {
		return (
			(HWUser.isGsuiteIntegrated() && HWUser.isGsuiteIntegratedForApp()) ||
			(HWUser.isOutlookIntegrated() && HWUser.isOutlookIntegratedForApp()) ||
			(HWUser.isZohoEmailIntegratedForUser() && HWUser.isSKZohoMailIntegrated())
		);
	}, [])

	const addVideoTypeTask = () => {
		const isVideoTaskPresent = taskTypeOption.filter((task) => task?.value === 'Video');

		if(!isVideoTaskPresent || isVideoTaskPresent?.length === 0) {
			const videoTypeTask = { id: 4, value: 'Video', label: 'Video'}
			dispatch(setSmartPlayBookStates({  
				taskTypeOption: [...taskTypeOption, videoTypeTask],
				taskNonAcccountTypeOption: [...taskNonAcccountTypeOption, videoTypeTask],
				taskOnlyOption: [...taskOnlyOption, videoTypeTask],
			}))
		}
	}

	const addCascadeTypeTask = () => {
		const isCascadeTaskPresent = taskTypeOption.filter((task) => task?.value === 'Cascade');

		if(!isCascadeTaskPresent || isCascadeTaskPresent?.length === 0) {
			const cascadeTypeTask = { id: 5, value: 'Cascade', label: 'Cascade', infoText: 'Completing this activity will trigger a new playbook. This is one time activity.' };

			dispatch(setSmartPlayBookStates({  
				taskTypeOption: [...taskTypeOption, cascadeTypeTask],
				taskNonAcccountTypeOption: [...taskNonAcccountTypeOption, cascadeTypeTask],
				taskOnlyOption: [...taskOnlyOption, cascadeTypeTask],
			}))
		}
	}

	// console.log("============= video ", playbookRbackScope(["task.type.Video:Enabled"]));
	// console.log("============= cascade", playbookRbackScope(["task.type.Cascade:Enabled"]))
	
	// Code to add video task type based on rBack scope
	if(playbookRbackScope(["task.type.Video:Enabled"])) {
		addVideoTypeTask();
	}
	//Code to add cascade type task based on rBack scope
	if(playbookRbackScope(["task.type.Cascade:Enabled"])) {
		addCascadeTypeTask();
	}

	useEffect(() => {
		if (HWUser.isOutlookIntegratedForApp() && !playbookRbackScope(["CustomerPortalUser:Enabled"])) {
			smartFilterTokenService.getMainDashboard().then((response) => {
			})
		}
		return () => {
			dispatch(setSmartPlayBookStates({ gettingStartedDetails: null }))
		}
	}, [dispatch])

	const checkForRedirect = useCallback(() => {
		let pathName = window.location.search;
		const token = new URLSearchParams(pathName)?.get('token');
		const action = new URLSearchParams(pathName)?.get('action')
		let decodedToken
		if (token) {
			decodedToken = JSON.parse(atob(token));
			const template = decodedToken.templateId;
			const callingFrom = decodedToken.callingFrom;
			const outcome = decodedToken.outcomeId;
			if (callingFrom === "tp_collaborator_added" || callingFrom === "tp_edited" || callingFrom === "tp_comment" || callingFrom === "tp_file_attached") {
				setInflowActiveTab({ title: 'Touchpoints', id: 'Touchpoints', path: 'touchpoint_list_view', primary: true, isEnable: access?.smartTouchpoint?.viewTab(), rootPath: "/dashboard/workspace/Touchpoints" })
			} else if (callingFrom === "collaborator_added" || callingFrom === "task_edited" || callingFrom === "comment" || callingFrom === "file_attached") {
				setInflowActiveTab({ title: 'Tasks', id: 'timeline', path: 'task_list', isEnable: true, rootPath: "/dashboard/workspace/timeline" });

			}
			else if (callingFrom === "PlaybookComment" || callingFrom === "Summary") {
				if (decodedToken?.isArchived && decodedToken?.isArchived !== "false") {
					let attribute = [{ attribute: 'playbook_live_status', name: 'Status', condition: null, values: ['Archived'] }]
					smartFilterTokenService?.addFeatureFilter(attribute, true, null, "playbook_live");
					let existingFilter = cloneDeep(smartFilterTokenService?.token);
					let request = {
						appId: HWUser.appId(),
						orgId: HWUser.orgId(),
						userId: HWUser.userId(),
						...existingFilter
					}
					smartFilterTokenService.updateUserFilterPreference(request).then((response) => {
						setSkSmartFilterToken(response?.data);
						setTimeout(() => {
							setInflowActiveTab({ title: 'Summary', id: 'playbooks_Summary', path: "playbook_live", isEnable: (access?.playbook?.playbook() || access?.playbook?.summary()), rootPath: "/dashboard/workspace/playbook/playbooks_Summary" });
						}, 500)
					}).catch(() => {
						// console.log("error") 
					})
				} else {
					let attribute = [{ attribute: 'playbook_live_status', name: 'Status', condition: null, values: ['Active'] }]
					smartFilterTokenService?.addFeatureFilter(attribute, true, null, "playbook_live");
					let existingFilter = cloneDeep(smartFilterTokenService?.token);
					let request = {
						appId: HWUser.appId(),
						orgId: HWUser.orgId(),
						userId: HWUser.userId(),
						...existingFilter
					}
					smartFilterTokenService.updateUserFilterPreference(request).then((response) => {
						setSkSmartFilterToken(response?.data);
						setTimeout(() => {
							setInflowActiveTab({ title: 'Summary', id: 'playbooks_Summary', path: "playbook_live", isEnable: (access?.playbook?.playbook() || access?.playbook?.summary()), rootPath: "/dashboard/workspace/playbook/playbooks_Summary" });
						}, 500)
					}).catch(() => {
						// console.log("error")
					})
				}
				setSummaryData({ playbookId: decodedToken?.playbookId, commentId: decodedToken?.commentId, unsubscribe: decodedToken?.unsubscribe })
				setOutcomeId(outcome);
			}
			else if (callingFrom === "Template") {
				if (decodedToken?.isArchived && decodedToken?.isArchived !== "false") {
					let attribute = [{ attribute: 'playbook_template_status', name: 'Status', condition: null, values: ['Archived'] }];
					smartFilterTokenService?.addFeatureFilter(attribute, true, null, "playbook_template");
					let existingFilter = cloneDeep(smartFilterTokenService?.token);
					let request = {
						appId: HWUser.appId(),
						orgId: HWUser.orgId(),
						userId: HWUser.userId(),
						...existingFilter
					}
					
					setTimeout(() => {
						smartFilterTokenService.updateUserFilterPreference(request).then((response) => {
							setSkSmartFilterToken(response?.data);
							setInflowActiveTab({ title: 'Templates', id: 'template', path: "playbook_template", isEnable: (access?.playbook?.playbook() || access?.playbook?.template()), rootPath: "/dashboard/workspace/playbook/template" });
							setforceRefresh(new Date().getMilliseconds());
						}).catch(() => {
							// console.log("error") 
						})
					}, 3300)

				} else {
					if (template) {
						setActiveTemplateId(template);
						setActiveTempateTab("create_template");
					} else {
						let attribute = [{ attribute: 'playbook_template_status', name: 'Status', condition: null, values: ['Active'] }];
						smartFilterTokenService?.addFeatureFilter(attribute, true, null, "playbook_template");
						let existingFilter = cloneDeep(smartFilterTokenService?.token);
						let request = {
							appId: HWUser.appId(),
							orgId: HWUser.orgId(),
							userId: HWUser.userId(),
							...existingFilter
						}
						smartFilterTokenService.updateUserFilterPreference(request).then((response) => {
							setSkSmartFilterToken(response?.data);
							setTimeout(() => {
								setInflowActiveTab({ title: 'Templates', id: 'template', path: "playbook_template", isEnable: (access?.playbook?.playbook() || access?.playbook?.template()), rootPath: "/dashboard/workspace/playbook/template" });
							}, 1800)
						}).catch(() => {
							// console.log("error") 
						})
					}
					setTemplateId(template);
					setOutcomeId(outcome);
				}
			} else if ( callingFrom === "getting_started"){
				dispatch(fetchGettingStartedData()).then((res) => {
					if(!res || !(!disableGettingStartedTab && HWUser.premiumFeature()?.GettingStarted === 'Enable')){
						setInflowActiveTab({ title: 'Tasks', id: 'timeline', path: 'task_list', isEnable: true, rootPath: "/dashboard/workspace/timeline" });
					}
				})
			}
			setTimeout(() => {
				window.history.replaceState(null, null, window.location.pathname);
			}, 7000)
		}
		if(action) {

			// Get the pathname from the URL
			
			
			// Extract the path after 'workspace'
			let url = window.location.pathname;
			const modulePath = url.substring(url.lastIndexOf('/') + 1);
			if(modulePath){
				if(modulePath === "template"){
					setInflowActiveTab({ title: 'Templates', id: 'template', path: "playbook_template", isEnable: (access?.playbook?.playbook() || access?.playbook?.template()), rootPath: "/dashboard/workspace/playbook/template" });
					setActiveTempateTab("create_template");
				}
				if(modulePath === "playbooks_Summary"){
					setInflowActiveTab({ title: 'Summary', id: 'playbooks_Summary', path: "playbook_live", isEnable: (access?.playbook?.playbook() || access?.playbook?.summary()), rootPath: "/dashboard/workspace/playbook/playbooks_Summary" });
					setSummaryData({ action: "create" });
				}
				if(modulePath === "PlayBooksAssignmentRules"){
					setInflowActiveTab({ title: 'Triggers', id: 'PlayBooksAssignmentRules', path: 'playbook_assignment_rule', isEnable: (access?.playbook?.playbook() || access?.playbook?.rule()), rootPath: "/dashboard/workspace/playbook/PlayBooksAssignmentRules" })
					setAssignmentRuleData({ action: "create" });
				}
			}
			setTimeout(() => {
				window.history.replaceState(null, null, window.location.pathname);
			}, 6000)
		}
	}, [])


	

	useEffect(() => {
		let isIntegrated = checkEmailIntegration()
		setEmailIntegrated(isIntegrated);
		setTimeout(() => {
			checkForRedirect()
		}, 500)
		dispatch(fetchTemplateOptions(HWUser.roleType()));
		// dispatch(fetchFunctionData());
	}, [])

	useEffect(() => {
		dispatch(setSmartPlayBookStates({ showPlaybookDetails: false, roleType: null, playbookPopoutRowData: null, expandedIds: [], activeOutcomeId: null }))
	}, [activeTab, dispatch])

	useEffect(() => {
		if (inflowActiveTab != null) {
			setInflowActiveTab(null);
		}
	}, [inflowActiveTab])



	const onTouchpointClick = () => {
		dispatch(setSmartMailState({ showTouchpointPopover: true }))
		if (getMailState?.touchpointData?.isTouchpointPresent) {
			dispatch(singleTouchpoint({ touchpointId: getMailState?.touchpointData?.touchpointId })).then((res) => {
				dispatch(setSmartMailState({ touchpointEditData: res }))
			});
		}
	}

	const userRoleType = HWUser.roleType();
	useEffect(() => {

		// if(localStorage.getItem("auth")){
		// 	dispatch(fetchListViewTasks(activeTab, searchText));
		// 	skTouchpointservice.palybookDataList(null);		
			
		// 	dispatch(fetchAssignmentRules());	
		// }
		if(!localStorage.getItem("auth")){
			dispatch(fetchProductOptionsAtOrgLevel());
			dispatch(fetchTaskProductOptions());
			dispatch(fetchTaskCollaboratorOptions());
			dispatch(fetchTaskAccountOptions());
			dispatch(fetchTochpointRefenceData());
			if (HWUser.roleType() !== 'NonAccount') {
				dispatch(fetchCategoryRefrenceData());
			}
			dispatch(fetchTaskCategoryReferenceData());
			dispatch(fetchRoleRefrenceData())
			dispatch(fetchAccontTypeRoleRefrenceData())
			dispatch(fetchUsereRefrenceData())
			dispatch(fetchPlaybookRbacScope("Playbook"))
			dispatch(fetchExternalUsers(true));
			setTimeout(() => {
					dispatch(fetchAccountList());
				}, 4000)
		}
	}, [dispatch])

	const getCurrentView = (view) => {
		const cachedConfig = window.localStorage.getItem("functionConfig")
		let functionConfig = cachedConfig && JSON.parse(cachedConfig);
		if (HWUser.roleType() === 'NonAccount' && (view === 'outComeView' || view === 'Outcome' || view === 'outcome')) {
			return "Function";
		}
		else if (HWUser.roleType() === 'Account' && functionConfig && functionConfig?.status === "Disabled" && (view === "Function" || view === 'functionView' || view === 'function')) {
			return "outComeView";
		}
		else if (HWUser.roleType() === 'Account' && functionConfig && functionConfig?.status === "Enabled" && (view === 'outComeView' || view === 'Outcome')) {
			return "functionView";
		}
	}


	useEffect(() => {
		let CustomerPortalUser = playbookRbackScope(["CustomerPortalUser:Enabled"]) 
		if (CustomerPortalUser) {
			setCustomerPortalEnabled(true);
			dispatch(setSmartPlayBookStates({ customerPortalUserEnabled: true }))
		} else {
			setCustomerPortalEnabled(false);
			dispatch(setSmartPlayBookStates({ customerPortalUserEnabled: false }))
		}
	}, [dispatch, outcomeStatus, playbookRbacResponse])

	useEffect(() => {
		const cachedFunctionconfig = window.localStorage.getItem("functionConfig");
		const cachedOutcomeConfig = window.localStorage.getItem("outcomeConfig");
		let functionConfig = cachedFunctionconfig && cachedFunctionconfig != "undefined"  && JSON.parse(cachedFunctionconfig);
		let outcomeConfig = cachedOutcomeConfig && cachedOutcomeConfig != "undefined"  && JSON.parse(cachedOutcomeConfig);

		setTaskToggleOptionsList([
			{ value: "List", lable: "List View", isEnabled: true, path: "task_list" },
			{ value: "timeline", lable: "Timeline", isEnabled: true, path: "task_list" },
			...(outcomeConfig && outcomeConfig?.status === 'Enabled' ? [{ value: "Outcome", lable: JSON.parse(window.localStorage.getItem("platform-currentUser"))?.outcomeConfig?.outcomeVisibleName || outcomeVisibleName || "Outcome", isEnabled: true, path: "task_list" }] : []),
			...(functionConfig && functionConfig?.status === 'Enabled' ? [{ value: "Function", lable: functionConfig?.functionVisibleName || "Function", isEnabled: true, path: "task_list" }] : []),

		])


	}, [customerPortalEnabled, dispatch, outcomeVisibleName])


	const onTemplateClick = useCallback(() => {
		setActiveTempateTab("create_template")
	}, [])


	const actions = useMemo(() => {
		return {
			onTemplateClick: () => { onTemplateClick() },
			editTemplate: (id) => {
				setSearchText('');
				setActiveTemplateId(id);
				setActiveTempateTab("create_template");
			},
		}
	}, [onTemplateClick])
	
	const onCloseCreateEditTemplates = useCallback(() => {
		setActiveTempateTab("template")
		setActiveTemplateId(null)
		if (activeTab !== 'template') {
			setforceRefresh(new Date().getMilliseconds())
		}
	}, [activeTab])


	const searchStr = debounce((data, searchTxt) => {

		let req = {
			mailType: activeTab === "Inbox" ? "Inbox" : "Sent",
			mailbox: activeTab === "Inbox" ? "inbox" : "sent",
			searchText: searchTxt,
		}
	
		if (activeTab === 'Touchpoints' && (touchpointActiveView === "list" || touchpointActiveView === 'outcome')) {
			dispatch(fetchTouchpointWithOutComesData(searchTxt))
		}
		if (activeTaskToggleView?.toLowerCase() === 'timeline' || activeTaskToggleView?.toLowerCase() === "Outcome"?.toLowerCase() || activeTaskToggleView?.toLowerCase() === "Function"?.toLowerCase()) {
			dispatch(fetchTaskWithCategories(activeTaskToggleView, searchTxt));
		}

		if (activeTaskToggleView?.toLowerCase() === 'List'?.toLowerCase() && activeTab === "timeline") {
			dispatch(fetchTaskOutcomeOptions(searchTxt));
			dispatch(fetchListViewTasks("List"?.toLowerCase(), searchTxt));
		}
		if (activeTab === 'template' && activeTempateTab !== 'create_template') {
			dispatch(fetchTemplatetOutComesData(searchTxt));


		}
		if (activeTab === 'Inbox' || activeTab === 'Sent') {
			dispatch(fetchMails(req, false));
		}
		if (activeTab === "playbooks_Summary") {
			dispatch(fetchPlaybookOutComesData(searchTxt));
		}
		if (activeTab === 'PlayBooksAssignmentRules') {
			dispatch(fetchAssignmentRules(req))
		}
		setSearchText(searchTxt);
		if (activeTab === 'Touchpoints' && touchpointActiveView?.toLowerCase() === "listView"?.toLowerCase()) {
			setforceRefresh(new Date().getMilliseconds());
		}
	}, 100)

	const searchCompObj = {
		searchFiled: 'contact',
		searchType: '',
		settingIcon: false,
		onChange: searchStr,
		searchLength: 18,
		// placeholder: "- Search -",
		className: activeTab === "playbooks_Summary" ? "play_book_summary--search" : ""
	}
	let offsetHeight = document.getElementsByClassName("sk_tab_filter_and_condition_container") && document.getElementsByClassName("sk_tab_filter_and_condition_container")?.[0]?.offsetHeight > 50 ? ((document.getElementsByClassName("sk_tab_filter_and_condition_container")[0].offsetHeight) - 50) : 0;

	const getHeaderName = useCallback(() => {
		let name = "";
		switch (activeTab) {
			case "timeline": name = "Tasks"; break;
			case "Outcome": name = "Tasks"; break;
			case "List": name = "Tasks"; break;
			case "Touchpoints": name = "Touchpoints"; break;
			case "Touchpoint_List": name = "Touchpoints"; break;
			case "template": name = "Template"; break;
			default: break;
		}
		return name
	}, [activeTab])


	const headerName = useMemo(() => {
		return getHeaderName()
	}, [getHeaderName])

	const applyPlaybookDelayedFilter = useCallback(() => {
		let activeFilter = {
			attribute: 'playbook_live_progress',
			name: 'Progress',
			values: ['Delayed']
		}
		smartFilterTokenService.addFeatureFilter(activeFilter);
	}, [])

	const playBookStatus = useMemo(() => {
		let featureFilter = smartFilterTokenService?.getFeatureFilters();
		featureFilter = featureFilter.filter(feature => feature.attribute === "playbook_live_status")
		return featureFilter.length > 0 ? featureFilter[0].values[0] : ''
	}, [smartFilterTokenService?.getFeatureFilters()])



	const setactiveplaybookView = useCallback((view) => {
		let fixedView = getCurrentView(view);
		if (fixedView) {
			setActivePlaybookView(fixedView);

		} else {
			setActivePlaybookView(view);
		}
		if (view !== activePlaybookView)
			setforceRefresh(new Date().getMilliseconds());
	}, [activePlaybookView])

	const setactiveTemplateView = useCallback((view) => {
		setActiveTemplateView(view);
		if (view !== activeTemplateView)
			setforceRefresh(new Date().getMilliseconds())
	}, [activeTemplateView])


	const setactivetouchpointView = useCallback((view) => {
		let fixedView = getCurrentView(view);
		if (fixedView) {
			dispatch(setSmartTochpointStates({touchpointActiveView:fixedView }))
		} else {
			dispatch(setSmartTochpointStates({touchpointActiveView:view }))
		}
		if (view !== touchpointActiveView)
			setforceRefresh(new Date().getMilliseconds())
	}, [dispatch, touchpointActiveView])

	const setactivePlaybookAnalyticsView = useCallback((view) => {
		setActivePlaybookAnalyticsView(view);
		if (view !== activePlaybookAnalyticsView)
			setforceRefresh(new Date().getMilliseconds())
	}, [activePlaybookAnalyticsView])

	const setactiveTaskToggleView = useCallback((view) => {
		let fixedView = getCurrentView(view);
		if (fixedView) {
			let viewToSet = fixedView === "functionView" ? "Function" : "Outcome";
			dispatch(setSmartTaskStates({
				activeTaskToggleView: viewToSet
			}))
		} else {
			dispatch(setSmartTaskStates({
				activeTaskToggleView: view?.toLowerCase()
			}))
		}
		if (view !== activeTaskToggleView)
			setforceRefresh(new Date().getMilliseconds())
	}, [dispatch, activeTaskToggleView])


	const togglePlaybookAnalyticsCompObj = useMemo(() => {
		return (
			{
				activeView: activePlaybookAnalyticsView,
				path: "playbook_analytics",
				list: [{ value: "User", lable: "Member View", isEnabled: true }
					, { value: "Account", lable: "Account View", isEnabled: HWUser.roleType() !== "NonAccount" }],
				onChangeActiveView: setActivePlaybookAnalyticsView,
				setActiveView: setactivePlaybookAnalyticsView,
			}
		)
	}, [activePlaybookAnalyticsView, setactivePlaybookAnalyticsView])



	const touchpointToggleCompObj = useMemo(() => {

		const cachedFunctionconfig = window.localStorage.getItem("functionConfig");
		const cachedOutcomeConfig = window.localStorage.getItem("outcomeConfig");
		let functionConfig = cachedFunctionconfig && cachedFunctionconfig != "undefined"  && JSON.parse(cachedFunctionconfig);
		let outcomeConfig = cachedOutcomeConfig && cachedOutcomeConfig != "undefined"  && JSON.parse(cachedOutcomeConfig);
		return (
			{
				activeView: touchpointActiveView,
				path: 'touchpoint_list_view',
				list: [
					{ value: "list", lable: "List View", isEnabled: true },
					...(outcomeConfig?.status === "Enabled" ? [{ value: "outcome", lable: JSON.parse(window.localStorage.getItem("platform-currentUser"))?.outcomeConfig?.outcomeVisibleName + " View", isEnabled: true }] : []),
					...( functionConfig?.status === "Enabled" ? [{ value: "function", lable: functionConfig?.functionVisibleName || "Function", isEnabled:  functionConfig?.status === "Enabled" }] : [])
				],
				setActiveView: setactivetouchpointView,
			}
		)
	}, [setactivetouchpointView, outcomeStatus, setactivetouchpointView])

	const toggleTemplateCompObj = useMemo(() => {
		return (
			{
				activeView: activeTemplateView,
				path: "playbook_template",
				list: [{ value: "listView", lable: "List View", isEnabled: true, activeIconClassName: 'active__template__list__icon', iconClassName: 'template__list__icon' },
				// { value: "outComeView", lable: outcomeVisibleName +" View", isEnabled: outcomeStatus === "Enabled" }
				{ value: "gridView", lable: "Grid View", isEnabled: true, activeIconClassName: 'active__template__grid__icon', iconClassName: 'template__grid__icon' }
				],
				setActiveView: setactiveTemplateView,
			}
		)
	}, [activeTemplateView, setactiveTemplateView, setActiveTemplateView])

	const toggleCompObj = useMemo(() => {
		const cachedFunctionconfig = window.localStorage.getItem("functionConfig");
		const cachedOutcomeConfig = window.localStorage.getItem("outcomeConfig");
		let functionConfig = cachedFunctionconfig && cachedFunctionconfig != "undefined"  && JSON.parse(cachedFunctionconfig);
		let outcomeConfig = cachedOutcomeConfig && cachedOutcomeConfig != "undefined"  && JSON.parse(cachedOutcomeConfig);
		return (
			{
				activeView: activePlaybookView,
				list: [
					{ value: "listView", lable: "List View", isEnabled: true },
					...( outcomeConfig?.status === "Enabled" ? [{ value: "outComeView", lable: JSON.parse(window.localStorage.getItem("platform-currentUser"))?.outcomeConfig?.outcomeVisibleName + " View", isEnabled: true }] : []),
					...(functionConfig?.status === "Enabled" ? [{ value: "functionView", lable: functionConfig?.functionVisibleName || "Function", isEnabled:  functionConfig?.status === "Enabled" }] : [])
				],
				setActiveView: setactiveplaybookView,
				onChangeActiveView: setActivePlaybookView,
				path: "playbook_live",
			}
		)
	}, [activePlaybookView, outcomeStatus, setactiveplaybookView])

	const taskToggleCompObj = useMemo(() => {
		return (
			{
				activeView: getSmartTaskStates()?.activeTaskToggleView,
				list: taskToggleOptionsList,
				setActiveView: setactiveTaskToggleView,
				path: 'task_list'
			}
		)
	}, [setactiveTaskToggleView, taskToggleOptionsList])


	const playbookPopupConfig = useMemo(() => {
		return {
			roleType: roleType,
			row: playbookPopoutRowData,
			expandedIds: expandedIds,
			outcomeId: activeOutcomeId
		}
	}, [activeOutcomeId, expandedIds, playbookPopoutRowData, roleType])

	const hideWarningPopup = useCallback(() => {
		setStatesData({ warningMsg: null, showWarningPopUp: false })
		if (window.localStorage.getItem("showGettingStarted")) {
			window.localStorage.setItem("showGettingStarted", false);
		}

	}, [])

	const hideExportPopup = useCallback(() => {
		setStatesData({ showWarningPopUp: false, exportMessage: null });
	})

	const confirmModalCongig = useMemo(() => {
		return {
			msg: state?.exportMessage || state?.warningMsg,
			headtxt: "Playbook",
			showOnlyOkButton: true,
			onlyOkAction: state?.exportMessage ? hideExportPopup: hideWarningPopup,
		}
	}, [state?.warningMsg, hideWarningPopup, state?.exportMessage, hideExportPopup])

	const setisUnsubscribe = useCallback((value) => {
		setStatesData({ warningMsg: value, showWarningPopUp: value ? true : false })
	}, [])

	useEffect(() => {
		if (disableGettingStartedTab && HWUser?.premiumFeature()?.GettingStarted === 'Enable' && window?.localStorage?.getItem("showGettingStarted") === "true") {
			setStatesData({
				warningMsg: `<p><strong style="font-weight:600;text-align:center; color: #7327CC;">Congratulations!</strong><br/> You have successfully completed the 'Getting Started' playbook.<br/>
		You are now equipped to seemlessly navigate and leverage the platform to suit your specific needs</p>`, showWarningPopUp: true
			})
		}
	}, [disableGettingStartedTab])

	const onSubTagClick =useCallback(async(feature) => {
		if (feature === 'delayed') {
			let attribute = [{ attribute: "due_in_list", name: "Due In", condition: null, values: ["Overdue"] }]
			await smartFilterTokenService.addFeatureFilter(attribute, true, [], "task_list");
			let existingFilter = cloneDeep(smartFilterTokenService?.token);
			let request = {
				appId: HWUser.appId(),
				orgId: HWUser.orgId(),
				userId: HWUser.userId(),
				...existingFilter
			}
			smartFilterTokenService.updateUserFilterPreference(request).then((response) => {
				setSkSmartFilterToken(response?.data);

				setTimeout(() => {
					navigate('/dashboard/workspace/timeline');
					setInflowActiveTab({ title: 'Tasks', id: 'timeline', primary: true, path: 'task_list', isEnable: access?.smartTask?.list(), rootPath: "/dashboard/workspace/timeline" });
					setforceRefresh(new Date().getMilliseconds())

				}, 500)
			}).catch(() => {
				dispatch(setSmartPlayBookStates({ playbookAnalyticsDashboardRedirectionLoader: false }));
			})
		}
		if (feature === 'total') {
			let filter = { id: "due_in_list" }
			smartFilterTokenService.removeFeatureFilter(filter, true, "task_list");
		
			let existingFilter = cloneDeep(smartFilterTokenService?.token);
			let request = {
				appId: HWUser.appId(),
				orgId: HWUser.orgId(),
				userId: HWUser.userId(),
				...existingFilter
			}
			smartFilterTokenService.updateUserFilterPreference(request).then((response) => {
				setSkSmartFilterToken(response?.data);

				setTimeout(() => {
					navigate('/dashboard/workspace/timeline');
					setInflowActiveTab({ title: 'Tasks', id: 'timeline', primary: true, path: 'task_list', isEnable: access?.smartTask?.list(), rootPath: "/dashboard/workspace/timeline" });
					setforceRefresh(new Date().getMilliseconds())

				}, 500)
			})
		}
	},[dispatch, navigate])

	const handleExport = () => {
		let req = {
			path: activeTab === 'PlayBooksDashboard' ? "playbook_analytics" : activeTab === 'timeline' ? "task_list" : activeTab === 'playbooks_Summary' ? 'playbook_live' : null
		};
		
		dispatch(exportFromWorkspace(req)).then( response => {
			setStatesData({ exportMessage: response, showWarningPopUp: true });
		})
	}


	const renderTouchPointComponents = useCallback((view) => {
		return (
			<>
					{activeTab === 'Touchpoints' && <TouchpointDashboard  searchText={searchText} touchpointTypeData={touchpointType} accountOptions={taskAccountOptions} activeTouchpointView={touchpointActiveView} activeTab={activeTab} productOptions={productDataForOrganization} collaboratorOptions={taskCollaboratorOptions} />}			
				</>
)
	}, [activeTab, touchpointActiveView, searchText, touchpointType, taskAccountOptions, productDataForOrganization, taskCollaboratorOptions])

	const renderSummaryComponents = useCallback((view) => {
		return (<>
			{(activeTab === "playbooks_Summary" && !view) ?
				<div key={"skeleton" + view} className='touchpointDashboard__container' style={{ background: "#fff" }}>
					<div style={{ width: "100%", height: "100%" }}><TableSkeleton /></div>
				</div>
				:
				<>
					{activeTab === "playbooks_Summary" &&
						(view === "outComeView" || view === "functionView") && !showPlaybookDetails &&
						<PlayBookSummaryScreenDashboard key={"outCome" + view} setisUnsubscribe={setisUnsubscribe} setInflowActiveTab={setInflowActiveTab} activePlaybookView={view} searchText={searchText} accountOptions={taskAccountOptions} productOptions={taskProductOptions} tokenOutcomeId={outcomeId} tokenSummaryData={summaryData} setSummaryData={setSummaryData} />}


					{activeTab === "playbooks_Summary" && view === "listView" && !showPlaybookDetails &&		
					<PlayBookSummaryListingGridTable key={"list" + view} setisUnsubscribe={setisUnsubscribe} offsetHeight={offsetHeight} setInflowActiveTab={setInflowActiveTab} activePlaybookView={view} searchText={searchText} accountOptions={taskAccountOptions} productOptions={taskProductOptions} tokenOutcomeId={outcomeId} tokenSummaryData={summaryData} setSummaryData={setSummaryData} />}
				</>
			}</>)
	}, [activeTab, offsetHeight, outcomeId, searchText, setisUnsubscribe, showPlaybookDetails, summaryData, taskAccountOptions, taskProductOptions])



	const renderTemplateComponents = useCallback((view) => {
		return (<>
			{/* {(
			(activeTab === 'template' || activeTempateTab === 'create_template')) ?
			<div className='touchpointDashboard__container' style={{ background: "#fff" }}>
				<div style={{ width: "100%", height: "100%" }}><TableSkeleton /></div>
			</div>
			: */}
			{(activeTab === 'template' || activeTempateTab === 'create_template') &&
			<PlayBookTemplateGridTable key={view + "templateView"} setSkSmartFilterToken={setSkSmartFilterToken} activeTemplateView={view} activeTemplateId={activeTemplateId} onCloseCreateEditTemplates={onCloseCreateEditTemplates} activeTempateTab={activeTempateTab} activeTab={activeTab} setUpdateUserPreference={(val) => { dispatch(setSmartPlayBookStates({ playbookDashboardData: null })); setUpdateUserPreference(val); }} setInflowActiveTab={setInflowActiveTab} tabs={tabs} offsetHeight={offsetHeight} actions={actions} tokenTemplateId={templateId} tokenOutcomeId={outcomeId} searchText={searchText} />
			}
		</>
		)
	}, [actions, activeTab, activeTempateTab, activeTemplateId, dispatch, offsetHeight, onCloseCreateEditTemplates, outcomeId, searchText, tabs, templateId])

	return (
		<>
		<Suspense fallback={<SkLoaderComponent isLoading={true} className="skLoaderComponent" digiPlaybooks={!window.localStorage.getItem('CustomTheme') && (window.location.host.includes('digiplaybooks'))}/>}>
		<div>
			{state.showWarningPopUp && <SmartConfirmModal dialogClassName={"disable__getting__started__modal"
			} {...confirmModalCongig} />}

			<div className='sk_smartFilterTab_wrap'>
			</div>

			<div className='smartpage'>
				<SmartFilterTab
					skSmartFilterToken={skSmartFilterToken}
					persistFilterValuesInDisabledState={false}
					propsActions={actions}
					disableAlltabs={activeTempateTab === 'create_template' ? true : false}
					tabs={tabs}
					activeTab={activeTab}
					defaultFirstSelectDisabled={true}
					loadingData={loadingData}
					disableFilterPreferenceApiCall={disableFilterPreferenceApiCall}
					onTabActive={onTabActive}
					updateUserPrefrence={updateUserPreference}
					setUpdateUserPrefrence={setUpdateUserPreference}
					forceRefresh={forceRefresh}
					inflowActiveTab={inflowActiveTab}
					showDateResetButn={(activeTab === 'timeline' || activeTab === 'Outcome' || activeTab === 'List') ? true : false}
					cutomeAttributeHeaderName={headerName}
					attributeSubTitle={headerName}
					disableGlobalShare={activeTab !== 'PlayBooksDashboard' && activeTab !== 'timeline' && activeTab !== 'playbooks_Summary'}
					exportXL={true}
					exortData={handleExport}
					desableFeatureAttribute={showPlaybookDetails || activeTempateTab === 'create_template' || activeTab === 'getting_started'}
					enableGlobalDateFilter={
						(activeTab === 'Sent' || activeTab === 'playbooks_Summary' || activeTab === 'Inbox' || activeTab === 'Calendar' || activeTab === 'template' || activeTab === 'PlayBooksAssignmentRules' || showPlaybookDetails || activeTab === 'PlayBooksDashboard' || activeTab === 'getting_started' || playbookRbackScope(["CustomerPortalUser:Enabled"]))  ? false : true}
					disableGlobalProductFilter={
						(activeTempateTab === 'create_template' || activeTab === 'Sent' || activeTab === 'Calendar' || activeTab === 'template' || activeTab === 'Inbox' || activeTab === 'PlayBooksAssignmentRules' || activeTab === 'getting_started' || HWUser.roleType() === "NonAccount" || showPlaybookDetails || playbookRbackScope(["CustomerPortalUser:Enabled"])) ? true : false}
					disableGlobalAccountFilter={
						(activeTempateTab === 'create_template' || activeTab === 'PlayBooksAssignmentRules' || activeTab === 'Calendar' || activeTab === 'getting_started' || activeTab === 'template' || HWUser.roleType() === "NonAccount" || showPlaybookDetails || (activeTab === 'playbooks_Summary' && playbookRbackScope(["CustomerPortalUser:Enabled"])) || (activeTab === 'timeline' && playbookRbackScope(["CustomerPortalUser:Enabled"]))
						) ? true : false}
					disableGlobalAttributeFilter={activeTempateTab === 'create_template' || activeTab === 'getting_started' || showPlaybookDetails ? true : false}
				/>
			</div>


			<div className='smartpage'>
				<div className='sk-smart-backgroung-light-purple-img sk-smart-backgroung-light-bg-height'>
					<Suspense fallback={<SkLoaderComponent isLoading={true} className="skLoaderComponent" digiPlaybooks={!window.localStorage.getItem('CustomTheme') && (window.location.host.includes('digiplaybooks'))}/>}><WorkspaceJoyride run={ getGlobalStates()?.showWorkspaceJoyride} /></Suspense>
					<Suspense fallback={<SkLoaderComponent isLoading={true} className="skLoaderComponent" digiPlaybooks={!window.localStorage.getItem('CustomTheme') && (window.location.host.includes('digiplaybooks'))}/>}><WorkspaceExternalUserJoyride run={ getGlobalStates()?.showExternalUserWorkspaceJoyride } /></Suspense>
					<div className={`sk-smart-backgroung-dark-purple-img-1 ${activeTab === 'playbooks_Summary' ? "playbook_summary_main__container" : ""}`}>
						<div className='sk-smart-backgroung_header_name workspace__header__container'>
							{activeTab === 'timeline' && <span className='workspace__title__header'><span className='bold'>Task</span>&nbsp;Summary
								{activeTaskToggleView?.toLowerCase() === 'List'?.toLowerCase() && <> - <span style={{textDecoration: "none"}} onClick={e => { onSubTagClick('total') }} className='filter__title'>Total: <span style={{textDecoration:"underline"}}>{totalTaskCount}</span>
								</span> |  <span style={{textDecoration: "none"}} onClick={e => { onSubTagClick('delayed') }} className='filter__title'>Delayed: <span style={{textDecoration:"underline"}}>{delayedTaskCount}</span></span></>} </span>}
							{(activeTab === 'Touchpoints' || activeTab === 'Touchpoint_List') && <span className='workspace__title__header'><span className='bold'>Touchpoint</span>&nbsp;Summary </span>}
							{(activeTab === 'getting_started') && !disableGettingStartedTab && <>
								<span>
									<span className='workspace__title__header'><span className='bold'>Getting</span>&nbsp;Started</span><UiLabel showToolTipWithHtml={true} length={0} placement="right" tooltipClass={"getting__started__info__tooltip"} label={"The 'Getting Started' Playbook will disappear once all the tasks on it are marked as completed"}>
										<img style={{ width: "16px", height: "16px", marginLeft: "5px" }} src={whiteInfoIcon} alt="info__icon" />
									</UiLabel>
								</span>
							</>
							}

							{(activeTab === 'Calendar') && <span className='workspace__title__header'><span className='bold'>Calendar</span>&nbsp;</span>}
							{activeTab === 'Inbox' && !getMailState?.showPreview && <span className='workspace__title__header'><span className='bold'>Email</span>&nbsp;Inbox </span>}
							{activeTab === 'Sent' && !getMailState?.showPreview && <span className='workspace__title__header'><span className='bold'>Sent</span>&nbsp;Emails </span>}
							{(activeTab === 'PlayBooksDashboard' && activeTempateTab !== 'create_template') && <span className='workspace__title__header'><span className='bold'>Playbook</span>&nbsp;Dashboard </span>}
							{(activeTempateTab === 'create_template') && <span className='workspace__title__header'>Define&nbsp;<span  className='bold'>Playbook Template</span></span>}
							{activeTab === 'PlayBooksAssignmentRules' && <span className='workspace__title__header'><span className='bold'>Playbook</span>&nbsp;Triggers </span>}
							{activeTab === 'template' && activeTempateTab !== 'create_template' && <span className='workspace__title__header'><span className='bold'>Template</span>&nbsp;Library </span>}
							{activeTab === 'playbooks_Summary' && !showPlaybookDetails && <span className='workspace__title__header'><span className='bold'>{playBookStatus}</span>&nbsp;Playbooks </span>}
							{activeTab === 'template' && activeTempateTab !== 'create_template' && !playbookRbackScope(["CustomerPortalUser:Enabled"]) && <div className='d-flex'>
								{!isSearchBarFocused && <span className='table__toggle--container'>
									<SkToggle key='template' {...toggleTemplateCompObj} />
								</span>}
								{<SKSearch {...searchCompObj} setIsSearchBarFocused={setIsSearchBarFocused} activeTab={inflowActiveTab} />}
							</div>
							}
							{getMailState?.showPreview &&
								<span className='mail__preview__container'>
									<div className='sk_smart__back__btn__container-icon' onClick={(e) => dispatch(setSmartMailState({ showPreview: false }))} style={{ position: "inherit" }}></div>
									<span className='email__preview__title__header'>{getMailState?.touchpointData?.subject}&nbsp;</span>
									<div className={!getMailState?.touchpointData?.isTouchpointPresent ? 'mail__preview__touchpoint__icon' : 'mail__preview__touchpoint__icon__added'} onClick={e => onTouchpointClick()}></div>
								</span>}
							{(((activeTab === 'Inbox' || activeTab === 'Sent') && !getMailState?.showPreview && emailIntegrated) /*|| (activeTab === 'template' ||  activeTab === 'PlayBooksAssignmentRules' )*/) &&
								<SKSearch {...searchCompObj} setIsSearchBarFocused={setIsSearchBarFocused} inflowActiveTab={activeTab} />
							}

							{activeTab === "playbooks_Summary" && !showPlaybookDetails &&
								<span className='d-flex playbook_summary_details--container'>
									<span className='workspace__title__header'>Playbooks: <span className=''>&nbsp;{playbookDashboardData?.livePlaybooks || 0}</span></span>
									<span className='virtical_bar'></span>
									{(HWUser.roleType() !== "NonAccount" && (!playbookRbackScope(["CustomerPortalUser:Enabled"]))) && <><span className='workspace__title__header'>Accounts Impacted: <span className=''>&nbsp;{playbookDashboardData?.accountsImpacted || 0}</span></span>
										<span className='virtical_bar'></span></>}
									<span className='workspace__title__header'>Delayed: <span className='cursor-pointer under-line' onClick={() => { applyPlaybookDelayedFilter() }}>{playbookDashboardData?.delayedLivePlaybooks || 0}</span></span>
									{(!playbookRbackScope(["CustomerPortalUser:Enabled"])) &&
										<span className='virtical_bar'></span>
									}

									{(!playbookRbackScope(["CustomerPortalUser:Enabled"])) && <span>
										<SkToggle key='playbook'  {...toggleCompObj} />
									</span>
									}

								</span>}
							{activeTab === "playbooks_Summary" && showPlaybookDetails &&
								<>
									<div className='playbook_detail_back_btn--container'>
										<div className='sk_smart__back__btn__container-icon' onClick={(e) => { dispatch(setSmartPlayBookStates({ showPlaybookDetails: false, roleType: null, playbookPopoutRowData: null, expandedIds: [], activeOutcomeId: null })); setforceRefresh(new Date().getMilliseconds()) }}></div>
										<span className='workspace__title__header'>{playbookPopoutRowData?.name} </span>
									</div>
								</>

							}

							{(activeTab === "Touchpoints" || activeTab === 'Touchpoint_List') && <> <div className='touchpint_type--container'>
								{touchpointTypeCount && touchpointTypeCount?.length > 0 && touchpointTypeCount.map((type, index) => {
									if (index <= 4 && !isSearchBarFocused) {
										return (
											<TouchpointType isClickable={true} index={index}  key={type.typeId + "_TouchpointType"} type={type} />
										)
									}
								})}
								{!isSearchBarFocused && <span style={{ marginLeft: "10px", marginRight: "-5px" }} className='table__toggle--container'>
									<SkToggle {...touchpointToggleCompObj} />
								</span>}
							</div>
								<SKSearch {...searchCompObj} setIsSearchBarFocused={setIsSearchBarFocused} activeTab={inflowActiveTab} />
							</>
							}
							{(activeTab === "timeline" || activeTab === 'Outcome' || activeTab === 'List') &&
								<div className='d-flex'>
									{!isSearchBarFocused && <span className='table__toggle--container'>
										<SkToggle {...taskToggleCompObj} />
									</span>}
									{access?.smartTask?.search() && <SKSearch {...searchCompObj} setIsSearchBarFocused={setIsSearchBarFocused} activeTab={inflowActiveTab} />}
								</div>
							}

							{activeTab === "PlayBooksDashboard" &&
								<Tooltip PopperProps={{
									popperOptions: {
										modifiers: {
											preventOverflow: { enabled: true, boundariesElement: 'window' },
										},
									},
								}} innerRef={togglePlaybookAnalyticsRef}
									classes={{ tooltip: classes?.customTooltip }}
									className='add__column__info__react__tooltip' title={"Toggle seamlessly between account view and member view for a comprehensive widget experience"} arrow placement="top">
									<div className='d-flex' >
											<span className='table__toggle--container'>
												<SkToggle key='analytics' {...togglePlaybookAnalyticsCompObj} />
											</span>
									</div>
								</Tooltip>
							}
						</div>

					</div>
					<div className='sk-smart-backgroung-dark-purple-img-2'></div>
					<div style={{
						maxHeight: activeTab === 'PlayBooksDashboard' ?
							"calc(75vh - (" + offsetHeight + "px))" : activeTab === 'timeline' && playbookRbackScope(["CustomerPortalUser:Enabled"]) ?
								"calc(71vh - (" + offsetHeight + "px))" : "calc(72vh - (" + offsetHeight + "px))",
						overflow: activeTab === 'PlayBooksDashboard' ? "hidden" : "",
						marginTop: ((activeTaskToggleView?.toLowerCase() === 'List'?.toLowerCase() && ((activeTab === 'timeline' || activeTab === 'Outcome' || activeTab === 'List'))) || (activeTab === 'Touchpoints' && touchpointActiveView === "listView")) ? '-20px' : ''
					}}
						className={` ${activeTab === 'PlayBooksAssignmentRules' ? 'assignment__rule__grid__table__container' : ""}  sk-smart-dashboard-container-content `}>

						<Suspense fallback={<SkLoaderComponent isLoading={true} className="skLoaderComponent" />}>
						{(activeTab === 'timeline' || activeTab === 'Outcome' || activeTab === 'List') && <TaskListsView
							offsetHeight={offsetHeight} activeSubTab={activeTaskToggleView} activeTab={activeTab} searchText={searchText} accountOptions={taskAccountOptions} productOptions={taskProductOptions} collaboratorOptions={playbookRbackScope(["CustomerPortalUser:Enabled"]) ? clientUsersReferenceList : taskCollaboratorOptions} />}

						
						{renderTouchPointComponents(touchpointActiveView)}
						{renderSummaryComponents(activePlaybookView)}
						{renderTemplateComponents(activeTemplateView)}
						

						{activeTab === 'PlayBooksDashboard' && activeTempateTab !== 'create_template' && 		
						<PlaybookAnalyticsDashboard
							key={"analytics" + activePlaybookAnalyticsView}
							setSkSmartFilterToken={setSkSmartFilterToken}
							activePlaybookAnalyticsView={activePlaybookAnalyticsView}
							offsetHeight={offsetHeight} setforceRefresh={setforceRefresh} setDefaultSelectedSummaryScreenOutcomeIds={setOutcomeId} setUpdateUserPreference={(val) => { dispatch(setSmartPlayBookStates({ playbookDashboardData: null })); setUpdateUserPreference(val) }} setInflowActiveTab={setInflowActiveTab} tabs={tabs} />}

						{activeTab === 'PlayBooksAssignmentRules' && <PlayBookAssignmentRulesGridTable offsetHeight={offsetHeight} accountOptions={taskAccountOptions} productOptions={taskProductOptions} assignmentRuleData={assignmentRuleData} setAssignmentRuleData={setAssignmentRuleData} tabs={tabs} setInflowActiveTab={setInflowActiveTab} setSkSmartFilterToken={setSkSmartFilterToken}/>}
						{(activeTab === 'Inbox' || activeTab === 'Sent') && 		
						<SmartEmail emailIntegrated={emailIntegrated} activeTab={activeTab} />}

						{activeTab === 'Calendar' && <Suspense fallback={<SkLoaderComponent isLoading={true} className="skLoaderComponent" digiPlaybooks={!window.localStorage.getItem('CustomTheme') && (window.location.host.includes('digiplaybooks'))}/>}><SmartCalendar emailIntegrated={emailIntegrated} /></Suspense>}

						{activeTab === "playbooks_Summary" && showPlaybookDetails && <ActivePlaybookDetails key={"active-playbook-details"} {...playbookPopupConfig} />}

						{(activeTab === 'getting_started' && !disableGettingStartedTab) &&<SKGettingStartedDashboard key={"getting-started-details"} setActiveTab={setInflowActiveTab} />}
						</Suspense>
					</div>
					{playbookRbackScope(["CustomerPortalUser:Enabled"]) &&
						<div className='sk__powered__by__wrap'>
							Powered by SmartKarrot
						</div>
					}
				</div>

			</div>
		</div>
		</Suspense>
		</>
	);
}

export default memo(Workspace);
